import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ReadContractErrorType } from "viem";

import {
    GetProjectsParams,
    getProjects as getProjectsFromBackend,
} from "src/services/api";
import { getChartsDataByProjectIds } from "src/slices/projectChartDataSlice";

import { RootState } from "src/store";

export type BackendProject = {
    id: number;
    name: string;
    price: number;
    latestUpdateFrequency: number;
    words: Array<{
        id: number;
        text: string;
    }>;
};

interface IProjectState {
    projectsIdsPerPage: Record<number, number[]>;
    totalProjects: number;
    projectsById: Record<number, BackendProject>;
    statuses: Record<
        "getProjects",
        "idle" | "loading" | "succeeded" | "failed"
    >;
    error: string | null;
}

const initialState: IProjectState = {
    projectsIdsPerPage: {},
    totalProjects: 0,
    projectsById: {},
    statuses: {
        getProjects: "idle",
    },
    error: null,
};

// Thunk for fetching projects
export const getProjects = createAsyncThunk<
    {
        projects: BackendProject[];
        totalProjects: number;
        page: number;
        rowsPerPage: number;
    },
    GetProjectsParams
>("project/getProjects", async (rest, thunkAPI) => {
    try {
        const projects = await getProjectsFromBackend(rest);
        const projectIds = projects.items.map(({ id }) => id);
        if (projectIds.length) {
            thunkAPI.dispatch(
                getChartsDataByProjectIds({
                    projectIds,
                }),
            );
        }

        return {
            projects: projects.items,
            totalProjects: projects.total,
            page: projects.page || 0,
            rowsPerPage: projects.rowsPerPage || 0,
        };
    } catch (_error: unknown) {
        const error = _error as AxiosError | ReadContractErrorType;
        console.warn("Error while fetching projects: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.fulfilled, (state, { payload }) => {
                state.statuses.getProjects = "succeeded";
                const { projects, totalProjects, page } = payload;
                const projectsById = { ...state.projectsById };
                state.projectsIdsPerPage[page] = [];
                projects.forEach((project) => {
                    if (!state.projectsById[project.id]) {
                        state.projectsById[project.id] = {} as BackendProject;
                    }
                    projectsById[project.id] = {
                        ...state.projectsById[project.id],
                        ...project,
                    };
                    state.projectsIdsPerPage[page].push(project.id);
                });
                state.projectsById = projectsById;
                state.totalProjects = totalProjects;
            })
            .addCase(getProjects.pending, (state) => {
                state.statuses.getProjects = "loading";
                state.error = null;
            })
            .addCase(getProjects.rejected, (state, { error }) => {
                if (error.message !== "Aborted") {
                    state.statuses.getProjects = "failed";
                    state.error = error.message as string;
                }
            });
    },
});

const selectProjectState = (state: RootState) => state.project;
export const selectProjectsByPage = (page: number) =>
    createSelector(
        [selectProjectState],
        (projectState) =>
            projectState.projectsIdsPerPage[page]?.map(
                (id) => projectState.projectsById[id],
            ) || [],
    );
export const selectTotalProjects = (state: RootState) =>
    state.project.totalProjects;
export const selectProjectStatuses = (state: RootState) =>
    state.project.statuses;

export default projectSlice.reducer;
