// * ROUTES IN THE APP * ----------------------

// SubPath Routes
export const SUBPATHS = {};

// PATHS object with linked SUBPATHS
export const PATHS = {
    ERROR: "/error",
    LANDING: "/",
    NOT_FOUND: "/404",
    OFFERS: "/offers",
};

export enum CURRENCY_SYMBOL {
    ETH = "ETH",
    USD = "USD",
    GWEI = "Gwei",
    WEI = "wei",
}

export type CURRENCY_SYMBOL_TYPE =
    (typeof CURRENCY_SYMBOL)[keyof typeof CURRENCY_SYMBOL];

export const ONE_GWEI_IN_WEI = "1000000000";
export const FOUR_DECIMALS_ETH_IN_WEI = "100000000000000";

export const FORECAST_DIRECTION_ABOVE = 0;
export const FORECAST_DIRECTION_BELOW = 1;
export const BET_TYPE_AGREE = 0;
export const BET_TYPE_DISAGREE = 1;

export const DEFAULT_ROWS_PER_PAGE = 25;
export const MIN_BET_AMOUNT = 1;
export const TOP_25_PROJECTS_COUNT = 25;
