import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { addSeconds } from "date-fns";
import { useContext, useState } from "react";
import { WriteContractErrorType } from "viem";

import useBalance, { LOW_BALANCE_THRESHOLD } from "src/hooks/useBalance";
import { ContractType } from "src/hooks/useContract";
import { ForecastDirection } from "src/services/api";
import {
    CreateForecastParams,
    createForecast,
} from "src/services/forecastContract";
import { getForecastsTypesCounts } from "src/slices/forecastSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import AmountInput from "src/components/AmountInput";
import SearchProjectAutocomplete from "src/components/SearchProjectAutocomplete";
import StyledModal from "src/components/StyledModal";

import { CURRENCY_SYMBOL, MIN_BET_AMOUNT } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch } from "src/store";
import { formatPrediction, waitFor } from "src/utils/common";
import { formatCurrency, formatUSDToWei } from "src/utils/formatCurrency";
import { formatDate } from "src/utils/formatDate";
import { bpsToFrequency, frequencyToBPS } from "src/utils/numberFormat";

export type CreateForecastFormParams = Partial<CreateForecastParams> & {
    predictedBps: string;
    amountUSD: string;
    projectName?: string;
};

export default function CreateForecast({
    slidingTimeWindowSeconds,
}: {
    slidingTimeWindowSeconds: number;
}) {
    const { selectedAccount } = useContext(WalletContext);
    const { contract } = useContext(ContractContext);
    const dispatch = useAppDispatch();
    const [
        createForecastBetAmountModalIsOpen,
        setCreateForecastBetAmountModalIsOpen,
    ] = useState(false);
    const { balance } = useBalance({
        address: selectedAccount,
        fetchInterval: createForecastBetAmountModalIsOpen ? 2_000 : null,
    });

    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState<CreateForecastFormParams>({
        direction: ForecastDirection.ABOVE,
        projectId: undefined,
        projectName: undefined,
        predictedBps: "",
        amountUSD: "",
    });
    const { rate } = useContext(CurrencyRateContext);
    const [amountError, setAmountError] = useState<string | null>(null);

    const minDateTime = addSeconds(new Date(), slidingTimeWindowSeconds * 2);
    const canOpenModal =
        params.projectId &&
        params.direction &&
        params.predictedBps &&
        params.settlingDeadline &&
        params.settlingDeadline.getTime() > minDateTime.getTime();
    const paramsAreValid = canOpenModal && params.amountUSD && !amountError;

    const sendForecast = async () => {
        let error;
        if (!contract) {
            error = "Contract is not defined";
        }
        if (!paramsAreValid) {
            error = "Parameters are not valid";
        }
        if (!selectedAccount) {
            error = "You are not connected";
        }
        if (!rate) {
            error = "Need rate to convert $ to ETH";
        }
        if (error) {
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error,
                }),
            );
            return;
        }
        setLoading(true);
        try {
            const oldCounts = await dispatch(
                getForecastsTypesCounts({
                    userAddress: selectedAccount ?? undefined,
                    onlyUserForecasts: true,
                }),
            ).unwrap();
            await createForecast(
                contract as ContractType,
                {
                    ...params,
                    predictedFrequency: BigInt(
                        bpsToFrequency(Number(params.predictedBps)),
                    ),
                    amount: formatUSDToWei({
                        rate,
                        valueToConvert: params.amountUSD,
                    }),
                } as CreateForecastParams,
            );

            await waitFor(2_000, async () => {
                const newCounts = await dispatch(
                    getForecastsTypesCounts({
                        userAddress: selectedAccount ?? undefined,
                        onlyUserForecasts: true,
                    }),
                ).unwrap();
                return Boolean(
                    newCounts && oldCounts && newCounts.all > oldCounts.all,
                );
            });
            dispatch(
                getForecastsTypesCounts({
                    userAddress: selectedAccount ?? undefined,
                    onlyUserForecasts: false,
                }),
            );
        } catch (_error) {
            const error = _error as WriteContractErrorType;
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error.message,
                }),
            );
        } finally {
            setLoading(false);
            setCreateForecastBetAmountModalIsOpen(false);
            setParams({
                direction: ForecastDirection.ABOVE,
                projectId: undefined,
                projectName: undefined,
                predictedBps: "",
                amountUSD: "",
            });
        }
    };

    return (
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
            <Typography component="span">I predict that</Typography>
            <SearchProjectAutocomplete
                onChange={(value) =>
                    setParams((prev) => ({
                        ...prev,
                        projectId: value?.id,
                        projectName: value?.name,
                        predictedBps: value?.latestUpdateFrequency
                            ? frequencyToBPS(value?.latestUpdateFrequency ?? 0)
                            : "-",
                    }))
                }
                sx={{ width: "10rem" }}
                size="small"
            />
            <Typography component="span">'s mindshare will go</Typography>
            <FormControl sx={{ width: "4rem" }}>
                <Select
                    labelId="create-forecast-direction-label"
                    id="create-forecast-direction"
                    value={params.direction}
                    onChange={(event: SelectChangeEvent<ForecastDirection>) =>
                        setParams((prev) => ({
                            ...prev,
                            direction: event.target.value as ForecastDirection,
                        }))
                    }
                    size="small"
                >
                    <MenuItem value={ForecastDirection.ABOVE}>{">="}</MenuItem>
                    <MenuItem value={ForecastDirection.BELOW}>{"<"}</MenuItem>
                </Select>
            </FormControl>
            <AmountInput
                showUnit
                unit="bps"
                placeholder="-"
                value={params.predictedBps}
                onChange={(event) => {
                    setParams((prev) => ({
                        ...prev,
                        predictedBps: event.target.value,
                    }));
                }}
                sx={{ width: "8rem" }}
                size="small"
            />
            <Typography>at</Typography>
            <DateTimePicker
                minDateTime={minDateTime}
                value={params.settlingDeadline ?? null}
                onChange={(value) =>
                    setParams((prev) => ({
                        ...prev,
                        settlingDeadline: value ?? undefined,
                    }))
                }
                views={["year", "month", "day", "hours", "minutes"]}
                sx={{
                    "& .MuiInputBase-input": {
                        padding: "8px 0 8px 8px",
                    },
                }}
            />
            <Tooltip
                title={`Min date is ${formatDate(minDateTime)} to let users the time to bet`}
            >
                <InfoIcon />
            </Tooltip>
            <LoadingButton
                onClick={() => setCreateForecastBetAmountModalIsOpen(true)}
                disabled={!canOpenModal}
                variant="contained"
                loading={loading}
            >
                Send
            </LoadingButton>
            {rate && createForecastBetAmountModalIsOpen && (
                <StyledModal
                    open={createForecastBetAmountModalIsOpen}
                    onClose={
                        !loading
                            ? () => setCreateForecastBetAmountModalIsOpen(false)
                            : undefined
                    }
                >
                    <Stack padding={1} height="95%">
                        <IconButton
                            onClick={() =>
                                setCreateForecastBetAmountModalIsOpen(false)
                            }
                            sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                zIndex: 100,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogTitle align="left">Last step</DialogTitle>
                        <DialogContent>
                            <Typography mb={2} textAlign="left">
                                {formatPrediction({
                                    forecastFormParams: params,
                                })}
                            </Typography>
                            <Stack direction="row" alignItems="center" gap={2}>
                                <AmountInput
                                    showUnit
                                    sx={{ flex: 2 }}
                                    unit="$"
                                    placeholder="amount to bet"
                                    value={params.amountUSD}
                                    onChange={(event) => {
                                        setAmountError(null);
                                        const newAmountUSD = event.target.value;
                                        setParams((prev) => ({
                                            ...prev,
                                            amountUSD: newAmountUSD,
                                        }));
                                        const amountETH = formatUSDToWei({
                                            rate,
                                            valueToConvert: newAmountUSD,
                                        });
                                        if (
                                            newAmountUSD !== "" &&
                                            Number(newAmountUSD) <
                                                MIN_BET_AMOUNT
                                        ) {
                                            setAmountError(
                                                `Min bet amount is $${MIN_BET_AMOUNT}`,
                                            );
                                        } else if (
                                            !balance ||
                                            amountETH + LOW_BALANCE_THRESHOLD >
                                                balance
                                        ) {
                                            setAmountError(
                                                "Not enought funds to be able to complete the bet",
                                            );
                                        }
                                    }}
                                    error={Boolean(amountError)}
                                    helperText={amountError}
                                />
                                {rate && (
                                    <Typography sx={{ flex: 1 }}>
                                        (
                                        {params.amountUSD
                                            ? formatCurrency({
                                                  valueToConvert:
                                                      formatUSDToWei({
                                                          rate,
                                                          valueToConvert:
                                                              params.amountUSD,
                                                      }),
                                                  currencySymbol:
                                                      CURRENCY_SYMBOL.ETH,
                                                  rate,
                                                  toFixed: 6,
                                              })
                                            : "0 ETH"}
                                        )
                                    </Typography>
                                )}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    setCreateForecastBetAmountModalIsOpen(false)
                                }
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={sendForecast}
                                disabled={loading || !paramsAreValid}
                                loading={loading}
                            >
                                Confirm
                            </LoadingButton>
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </Stack>
    );
}
