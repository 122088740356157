import type { ForecastDotFun$Type } from "public/artifacts/contracts/ForecastDotFun.sol/ForecastDotFun";
import { useCallback, useEffect, useState } from "react";
import {
    Account,
    Address,
    BaseError,
    Chain,
    CustomTransport,
    GetContractReturnType,
    HttpTransport,
    PublicClient,
    WalletClient,
    decodeErrorResult,
    getContract,
} from "viem";

import usePublicClient from "src/hooks/usePublicClient";
import useWalletClient from "src/hooks/useWalletClient";
import {
    SeverityType,
    resetSnackbarFeedback,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { ContractContextType } from "src/contexts/ContractsContext";
import { useAppDispatch } from "src/store";

export type ContractType = GetContractReturnType<
    ForecastDotFun$Type["abi"],
    {
        public: PublicClient<HttpTransport, Chain>;
        wallet: WalletClient<CustomTransport, Chain, Account>;
    },
    Address
>;

export class ArtifactError extends Error {}

export class BadNetworkError extends Error {}

type JSONArtifact = {
    _format: string;
    contractName: string;
    sourceName: string;
    abi: ForecastDotFun$Type["abi"];
    bytecode: string;
    deployedBytecode: string;
    linkReferences: Record<string, unknown>;
    deployedLinkReferences: Record<string, unknown>;
};

export const fechArtifact = async (url: string) => {
    const response = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    if (response.status !== 200) {
        throw new ArtifactError("Could not load contract artifact");
    }

    const json = await response.json();
    return json as JSONArtifact;
};

export type ExtendedContractCallError = Error & {
    decodedError?: string;
    hash?: string;
    fullError?: Error;
};
const improveContractWithErrorParsingBeforeThrow = <T extends ContractType>(
    contract: T,
): T => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler: ProxyHandler<any> = {
            get(target, prop) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return async (...args: any) => {
                    try {
                        if (prop === "toJSON") {
                            console.warn("Not sure why toJSON is called");
                            return null;
                        }
                        return await target[prop](...args);
                    } catch (e: unknown) {
                        const error = e as ExtendedContractCallError;
                        let errorToThrow = error;
                        if (error instanceof BaseError && error.details) {
                            // This error parsing works only when the gas estimation fails
                            // if a transaction is reverted during the execution, there is no way to get the reverted reason (at least for now)
                            // see https://nalyze.atlassian.net/browse/MRMR-791?focusedCommentId=11669 for more details
                            try {
                                const regex = new RegExp(
                                    `(?<=error=)(.*)(?=})`,
                                );
                                const errorObjectAsString =
                                    error.details.match(regex)?.[0];
                                if (errorObjectAsString) {
                                    const parsedError = JSON.parse(
                                        `${errorObjectAsString}}`,
                                    );
                                    let decodedError;
                                    if (
                                        typeof parsedError.error?.data ===
                                        "string"
                                    ) {
                                        // Once deployed parsedError.error?.data = "0x..."
                                        decodedError = decodeErrorResult({
                                            abi: contract.abi,
                                            data: parsedError.error.data,
                                        });
                                    } else if (
                                        typeof parsedError.error?.data?.data ===
                                        "string"
                                    ) {
                                        // In local, parsedError.error?.data is directly something like:
                                        // { data: "0x...", message: "Error: VM Exception while processing transaction: reverted with custom error 'DuplicateMessage(\"0x3d8117581e40c657dd418305bcef16483037a82047baee3b0d055d8aa24ba7b3\")'" }
                                        decodedError = decodeErrorResult({
                                            abi: contract.abi,
                                            data: parsedError.error.data.data,
                                        });
                                    }
                                    if (decodedError) {
                                        // You can find a list of all possible viem error names here : https://viem.sh/docs/glossary/errors.html#errors
                                        errorToThrow = new Error(
                                            `${error.name}: ${decodedError.errorName}`,
                                        );
                                        errorToThrow.decodedError =
                                            JSON.stringify(
                                                decodedError,
                                                (_key, value) =>
                                                    typeof value === "bigint"
                                                        ? value.toString()
                                                        : value,
                                            );
                                    }
                                } else {
                                    const regexToGetTransactionHash =
                                        new RegExp(
                                            `(?<=transactionHash=")([^,]*)(?=",)`,
                                        );
                                    const transactionHash = error.details.match(
                                        regexToGetTransactionHash,
                                    )?.[0];
                                    if (error.shortMessage && error.name) {
                                        errorToThrow = new Error(
                                            `${error.name}: ${error.shortMessage}`,
                                        );
                                        errorToThrow.hash = transactionHash;
                                    }
                                }
                            } catch (error) {
                                console.error(
                                    "Error trying to parse error log",
                                    error,
                                );
                            }
                        }
                        errorToThrow.fullError = error;
                        throw errorToThrow;
                    }
                };
            },
        };
        if (contract.read) {
            contract.read = new Proxy(contract.read, handler);
        }
        if (contract.write) {
            contract.write = new Proxy(contract.write, handler);
        }
        if (contract.estimateGas) {
            contract.estimateGas = new Proxy(contract.estimateGas, handler);
        }
        if (contract.simulate) {
            contract.simulate = new Proxy(contract.simulate, handler);
        }
        return contract;
    } catch (e) {
        console.error("Could not enhance contract", e, contract);
        return contract;
    }
};

type GetContractFromJsonParams = {
    abi: ForecastDotFun$Type["abi"];
    contractAddress?: Address;
    publicClient: PublicClient<HttpTransport, Chain>;
    walletClient?: WalletClient<CustomTransport, Chain, Account>;
};

export const getContractFromJson = async ({
    abi,
    publicClient,
    walletClient,
}: GetContractFromJsonParams) => {
    const _contract = getContract({
        address: import.meta.env.VITE_CONTRACT_ADDRESS,
        abi,
        client: walletClient
            ? {
                  public: publicClient,
                  wallet: walletClient,
              }
            : publicClient,
    });

    return _contract;
};

const useContract = (): ContractContextType => {
    const dispatch = useAppDispatch();
    const [jsonArtifact, setJSONArtifact] = useState<JSONArtifact>();
    const [contract, setContract] = useState<ContractType>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const publicClient = usePublicClient();
    const walletClient = useWalletClient();

    useEffect(() => {
        const fetchArtifacts = async () => {
            try {
                const jsonArtifact = await fechArtifact(
                    import.meta.env.VITE_CONTRACT_ARTIFACT_URL,
                );
                setJSONArtifact(jsonArtifact);
            } catch (e: unknown) {
                const error = e as Error;
                console.error(error);
                setError(
                    `${error.message}.\nThe team has been informed!\nPlease come back and retry later.`,
                );
            }
        };

        fetchArtifacts();
    }, []);

    const refreshContract = useCallback(async () => {
        if (!jsonArtifact) {
            console.warn(
                "Skipping refreshContract while waiting for artifacts",
            );
            return;
        }
        setError(null);
        dispatch(resetSnackbarFeedback());
        try {
            if (jsonArtifact) {
                const contractInstance = await getContractFromJson({
                    abi: jsonArtifact.abi,
                    publicClient,
                    walletClient,
                });
                setContract(
                    improveContractWithErrorParsingBeforeThrow(
                        contractInstance as unknown as ContractType,
                    ),
                );
            } else {
                throw Error("Could not load contract(s)");
            }
        } catch (e: unknown) {
            const error = e as Error;
            setError(error.message);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message:
                        "Could not load contract. Are you on a valid network with a proper setup?",
                }),
            );
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [jsonArtifact, dispatch, publicClient, walletClient]);

    useEffect(() => {
        refreshContract();
    }, [dispatch, refreshContract, walletClient]);

    return {
        contract,
        error,
        loading,
    };
};

export default useContract;
