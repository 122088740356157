import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectToCamel, ToCamel, toCamel } from "ts-case-convert";

import {
    BackendForecast,
    GetForecastByIdParams,
    GetForecastsParams,
    getForecastById as getForecastByIdFromBackend,
    getForecasts as getForecastsFromBackend,
    getForecastsTypesCounts as getForecastsTypesCountsFromBackend,
} from "src/services/api";

import { RootState } from "src/store";

export type Forecast = ObjectToCamel<BackendForecast>;

interface IForecastState {
    forecastIdsByStatusAndPage: Record<
        ToCamel<GetForecastsParams["status"]>,
        Record<number, number[] | null>
    >;
    forecastTotalByStatus: Record<
        ToCamel<GetForecastsParams["status"]>,
        number | null
    >;
    userForecastTotalByStatus: Record<
        ToCamel<GetForecastsParams["status"]>,
        number | null
    >;
    forcecastById: Record<number, Forecast>;
    statuses: Record<
        "getForecasts" | "getForecastsTypesCounts" | "getForecastById",
        "idle" | "loading" | "succeeded" | "failed"
    >;
    error: string | null;
}

const initialState: IForecastState = {
    forecastIdsByStatusAndPage: {
        all: {},
        openForBet: {},
        waitSettle: {},
        settled: {},
    },
    forecastTotalByStatus: {
        all: null,
        openForBet: null,
        waitSettle: null,
        settled: null,
    },
    userForecastTotalByStatus: {
        all: null,
        openForBet: null,
        waitSettle: null,
        settled: null,
    },
    forcecastById: {},
    statuses: {
        getForecasts: "idle",
        getForecastsTypesCounts: "idle",
        getForecastById: "idle",
    },
    error: null,
};

// Thunk for fetching forecasts counts
export const getForecastsTypesCounts = createAsyncThunk(
    "forecast/getForecastsTypesCounts",
    async (
        params: Pick<GetForecastsParams, "userAddress" | "onlyUserForecasts">,
        thunkAPI,
    ) => {
        try {
            const counts = await getForecastsTypesCountsFromBackend(params);

            return counts;
        } catch (_error: unknown) {
            const error = _error as AxiosError | Error;
            console.warn(
                "Error while fetching forecasts types counts: ",
                error,
            );
            if (error.message === "Cancelled due to new request") {
                return;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching forecast by id
export const getForecastById = createAsyncThunk(
    "forecast/getForecastById",
    async (params: GetForecastByIdParams, thunkAPI) => {
        try {
            const forecast = await getForecastByIdFromBackend(params);

            return forecast;
        } catch (_error: unknown) {
            const error = _error as AxiosError | Error;
            console.warn(
                "Error while fetching forecasts types counts: ",
                error,
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching forecasts
export const getForecasts = createAsyncThunk(
    "forecast/getForecasts",
    async (params: GetForecastsParams, thunkAPI) => {
        try {
            const forecasts = await getForecastsFromBackend(params);
            return forecasts;
        } catch (_error: unknown) {
            const error = _error as AxiosError | Error;
            console.warn("Error while fetching forecasts: ", error);
            if (error.message === "Cancelled due to new request") {
                return;
            }
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const forecastSlice = createSlice({
    name: "forecast",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getForecasts.fulfilled, (state, { payload, meta }) => {
                if (payload) {
                    const { status, page } = meta.arg;
                    const newIds: number[] = [];
                    payload.items.forEach((forecast) => {
                        newIds.push(forecast.id);
                        state.forcecastById[forecast.id] = forecast;
                    });
                    state.forecastIdsByStatusAndPage[toCamel(status)][page] =
                        newIds;
                    state.forecastTotalByStatus[toCamel(status)] =
                        payload.total;
                    state.statuses.getForecasts = "succeeded";
                    state.error = null;
                }
            })
            .addCase(getForecasts.pending, (state) => {
                state.statuses.getForecasts = "loading";
                state.error = null;
            })
            .addCase(getForecasts.rejected, (state, { payload }) => {
                state.statuses.getForecasts = "failed";
                state.error = payload as string;
            })
            .addCase(getForecastById.fulfilled, (state, { payload }) => {
                state.forcecastById[payload.id] = payload;
                state.statuses.getForecastById = "succeeded";
                state.error = null;
            })
            .addCase(getForecastById.pending, (state) => {
                state.statuses.getForecastById = "loading";
                state.error = null;
            })
            .addCase(getForecastById.rejected, (state, { payload }) => {
                state.statuses.getForecastById = "failed";
                state.error = payload as string;
            })
            .addCase(
                getForecastsTypesCounts.fulfilled,
                (state, { payload, meta }) => {
                    if (payload) {
                        const { onlyUserForecasts } = meta.arg;
                        if (onlyUserForecasts) {
                            state.userForecastTotalByStatus = payload;
                        } else {
                            state.forecastTotalByStatus = payload;
                        }
                        state.statuses.getForecastsTypesCounts = "succeeded";
                        state.error = null;
                    }
                },
            )
            .addCase(getForecastsTypesCounts.pending, (state) => {
                state.statuses.getForecastsTypesCounts = "loading";
                state.error = null;
            })
            .addCase(getForecastsTypesCounts.rejected, (state, { payload }) => {
                state.statuses.getForecastsTypesCounts = "failed";
                state.error = payload as string;
            });
    },
});

export default forecastSlice.reducer;

const selectForecastState = (state: RootState) => state.forecast;

export const selectTotalForecasts = (state: RootState) =>
    state.forecast.forecastTotalByStatus["all"];

export const selectForecastsTotalByStatus = (
    status: GetForecastsParams["status"],
) =>
    createSelector(
        [selectForecastState],
        (forecastState) => forecastState.forecastTotalByStatus[toCamel(status)],
    );

export const selectForecastsByStatusAndPage = ({
    status,
    page,
}: {
    status: GetForecastsParams["status"];
    page: number;
}) =>
    createSelector(
        [selectForecastState],
        (forecastState) =>
            forecastState.forecastIdsByStatusAndPage[toCamel(status)]?.[
                page
            ]?.map((id) => forecastState.forcecastById[id]) || [],
    );

export const selectForecastById = (forecastId: number) =>
    createSelector(
        [selectForecastState],
        (forecastState) => forecastState.forcecastById[forecastId],
    );

export const selectForecastsTypesCounts = (state: RootState) =>
    state.forecast.forecastTotalByStatus;

export const selectUserForecastsTypesCounts = (state: RootState) =>
    state.forecast.userForecastTotalByStatus;

export const selectForecastsStatuses = (state: RootState) =>
    state.forecast.statuses;
