import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { getSlidingTimeWindowSeconds as getSlidingTimeWindowSecondsFromBackend } from "src/services/api";

import { RootState } from "src/store";

interface ISettingsState {
    slidingTimeWindowSeconds: number | null;
    statuses: Record<
        "getSlidingTimeWindowSeconds",
        "idle" | "loading" | "succeeded" | "failed"
    >;
    error: string | null;
}

const initialState: ISettingsState = {
    slidingTimeWindowSeconds: null,
    statuses: {
        getSlidingTimeWindowSeconds: "idle",
    },
    error: null,
};

export const getSlidingTimeWindowSeconds = createAsyncThunk(
    "settings/getSlidingTimeWindowSeconds",
    async (_params, thunkAPI) => {
        try {
            const slidingTimeWindowSeconds =
                await getSlidingTimeWindowSecondsFromBackend();

            return slidingTimeWindowSeconds;
        } catch (_error: unknown) {
            const error = _error as AxiosError | Error;
            console.warn(
                "Error while fetching slidingTimeWindowSeconds: ",
                error,
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getSlidingTimeWindowSeconds.fulfilled,
                (state, { payload }) => {
                    state.statuses.getSlidingTimeWindowSeconds = "succeeded";
                    state.slidingTimeWindowSeconds = payload;
                    state.error = null;
                },
            )
            .addCase(getSlidingTimeWindowSeconds.pending, (state) => {
                state.statuses.getSlidingTimeWindowSeconds = "loading";
                state.error = null;
            })
            .addCase(
                getSlidingTimeWindowSeconds.rejected,
                (state, { payload }) => {
                    state.statuses.getSlidingTimeWindowSeconds = "failed";
                    state.error = payload as string;
                },
            );
    },
});

export default settingsSlice.reducer;

export const selectSlidingTimeWindowSeconds = (state: RootState) =>
    state.settings.slidingTimeWindowSeconds;
