import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    IconButton,
    ModalProps,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";

import { BetType, ForecastDirection } from "src/services/api";
import { selectForecastById } from "src/slices/forecastSlice";

import BetButton from "src/components/BetButton";
import BetsTable from "src/components/BetsTable";
import ClaimWinningsButton from "src/components/ClaimWinningsButton";
import ForecastBetsPercentage from "src/components/ForecastBetsPercentage";
import StyledModal from "src/components/StyledModal";

import { CURRENCY_SYMBOL } from "src/constants";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { useAppSelector } from "src/store";
import { formatPrediction } from "src/utils/common";
import { formatCurrency } from "src/utils/formatCurrency";
import { frequencyToBPS } from "src/utils/numberFormat";

type ForecastDetailModalProps = Omit<ModalProps, "children" | "onClose"> & {
    forecastId: number;
    onClose: () => void;
};

export default function ForecastDetailModal({
    onClose,
    open,
    forecastId,
    ...props
}: ForecastDetailModalProps) {
    const forecast = useAppSelector(selectForecastById(forecastId));
    const { rate } = useContext(CurrencyRateContext);
    const [activeTab, setActiveTab] = useState<"forecast" | "activity">(
        "forecast",
    );

    const cannotBet =
        new Date(forecast.bettingDeadline) < new Date() || forecast.isSettled;

    return (
        <StyledModal {...props} open={open} onClose={onClose}>
            <Stack padding={1} height="95%">
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 100,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => {
                        setActiveTab(value);
                    }}
                >
                    <Tab value="forecast" label="Forecast" />
                    <Tab value="activity" label="Activity" />
                </Tabs>
                {activeTab === "forecast" && (
                    <Stack gap={2} mt={2}>
                        <Typography textAlign="left">
                            {rate && formatPrediction({ forecast, rate })}
                        </Typography>
                        <Stack direction="row" gap={1}>
                            <Box display="flex" justifyContent="right" flex={1}>
                                {cannotBet ? (
                                    <ForecastBetsPercentage
                                        forecast={forecast}
                                        betType={BetType.AGREE}
                                    />
                                ) : (
                                    <BetButton
                                        forecast={forecast}
                                        betType={BetType.AGREE}
                                        fullWidth
                                    />
                                )}
                            </Box>
                            <Box display="flex" justifyContent="left" flex={1}>
                                {cannotBet ? (
                                    <ForecastBetsPercentage
                                        forecast={forecast}
                                        betType={BetType.DISAGREE}
                                    />
                                ) : (
                                    <BetButton
                                        forecast={forecast}
                                        betType={BetType.DISAGREE}
                                        fullWidth
                                    />
                                )}
                            </Box>
                        </Stack>
                        {forecast.isSettled && (
                            <ClaimWinningsButton
                                forecast={forecast}
                                fullWidth
                            />
                        )}
                    </Stack>
                )}
                {activeTab === "activity" && (
                    <Stack gap={2} mt={2}>
                        <Stack gap={1}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography>Project:</Typography>
                                <Typography fontWeight="bold">
                                    {forecast.project.name}
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography>Volume:</Typography>
                                <Typography fontWeight="bold">
                                    {forecast.betsData?.totalAmount
                                        ? formatCurrency({
                                              valueToConvert: BigInt(
                                                  forecast.betsData.totalAmount,
                                              ),
                                              rate,
                                              currencySymbol:
                                                  CURRENCY_SYMBOL.USD,
                                              toFixed: 2,
                                          })
                                        : "$0"}
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography>Target:</Typography>
                                <Typography fontWeight="bold">
                                    {forecast.direction ===
                                    ForecastDirection.ABOVE
                                        ? ">="
                                        : "<"}
                                    {frequencyToBPS(
                                        forecast.predictedFrequency,
                                    )}{" "}
                                    bps
                                </Typography>
                            </Stack>
                        </Stack>
                        <BetsTable forecastId={forecastId} />
                    </Stack>
                )}
            </Stack>
        </StyledModal>
    );
}
