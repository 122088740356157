import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useContext, useState } from "react";
import { WriteContractErrorType } from "viem";

import { ContractType } from "src/hooks/useContract";
import { claimWinnings as claimWinningsFromContract } from "src/services/forecastContract";
import { Forecast, getForecastById } from "src/slices/forecastSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { CURRENCY_SYMBOL } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch } from "src/store";
import { waitFor } from "src/utils/common";
import { formatCurrency } from "src/utils/formatCurrency";

export default function ClaimWinningsButton({
    forecast,
    ...rest
}: LoadingButtonProps & {
    forecast: Forecast;
}) {
    const { rate } = useContext(CurrencyRateContext);
    const { contract } = useContext(ContractContext);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const { selectedAccount } = useContext(WalletContext);

    const claimWinnings = async () => {
        let error;
        if (!contract) {
            error = "Contract is not defined";
        }
        if (error) {
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error,
                }),
            );
            return;
        }
        setLoading(true);
        try {
            await claimWinningsFromContract(contract as ContractType, {
                forecastId: forecast.id,
            });
            await waitFor(2_000, async () => {
                const updatedForecast = await dispatch(
                    getForecastById({
                        forecastId: forecast.id,
                        userAddress: selectedAccount,
                    }),
                ).unwrap();
                return Boolean(
                    updatedForecast.userAddressBetsData &&
                        updatedForecast.userAddressBetsData.betsNotClaimed ===
                            0,
                );
            });
        } catch (_error) {
            const error = _error as WriteContractErrorType;
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error.message,
                }),
            );
        } finally {
            setLoading(false);
        }
    };

    return forecast?.userAddressBetsData?.betsNotClaimed &&
        forecast.userAddressBetsData.potentialWinnings &&
        rate ? (
        <LoadingButton
            variant="contained"
            loading={loading}
            onClick={claimWinnings}
            {...rest}
        >
            Claim my{" "}
            {formatCurrency({
                valueToConvert: BigInt(
                    forecast.userAddressBetsData.potentialWinnings,
                ),
                rate,
                currencySymbol: CURRENCY_SYMBOL.USD,
                toFixed: 2,
            })}
        </LoadingButton>
    ) : null;
}
