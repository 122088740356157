import { Link, Typography } from "@mui/material";
import { Address as AddressType } from "viem";

import { getEnvDependentChain } from "src/chains";
import { truncateAddress } from "src/utils/truncate";

export default function Address({ account }: { account: AddressType }) {
    const blockExplorerUrl =
        getEnvDependentChain()?.blockExplorers?.default.url;
    if (blockExplorerUrl) {
        return (
            <Link
                href={`${blockExplorerUrl}/address/${account}`}
                target="_blank"
                key={account}
            >
                {truncateAddress(account)}
            </Link>
        );
    } else {
        return (
            <Typography component="span" key={account}>
                {truncateAddress(account)}
            </Typography>
        );
    }
}
