import {
    Button,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { ObjectToCamel } from "ts-case-convert";

import { BackendBet, BetType, getBetsByForecastId } from "src/services/api";
import { selectForecastById } from "src/slices/forecastSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import Address from "src/components/Address";

import { CURRENCY_SYMBOL, DEFAULT_ROWS_PER_PAGE } from "src/constants";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { formatCurrency } from "src/utils/formatCurrency";

export default function BetsTable({ forecastId }: { forecastId: number }) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const forecast = useAppSelector(selectForecastById(forecastId));
    const { selectedAccount } = useContext(WalletContext);
    const { rate } = useContext(CurrencyRateContext);
    const [isLoading, setIsLoading] = useState(false);
    const [bets, setBets] = useState<Array<ObjectToCamel<BackendBet>>>([]);
    const [totalBets, setTotalBets] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const headerCellSX = {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    };

    const fetchForecastBets = useCallback(
        async (page: number) => {
            setIsLoading(true);
            try {
                const data = await getBetsByForecastId({
                    forecastId,
                    page,
                    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
                });
                if (data) {
                    setBets((prev) => [
                        ...prev,
                        ...data.items.filter(
                            ({ id }) => !prev.map((bet) => bet.id).includes(id),
                        ),
                    ]);
                    setTotalBets(data.total);
                    setPage(data.page);
                }
            } catch (_error: unknown) {
                const error = _error as AxiosError;
                if (error.message !== "Cancelled due to new request") {
                    dispatch(
                        setSnackbarFeedback({
                            type: SeverityType.ERROR,
                            message: error.message,
                        }),
                    );
                }
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, forecastId],
    );

    useEffect(() => {
        fetchForecastBets(page);
        return () => setPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, fetchForecastBets]);

    return (
        <Stack gap={1}>
            <Table
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                    },
                }}
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={headerCellSX} align="left">
                            From
                        </TableCell>
                        <TableCell sx={headerCellSX} align="right">
                            Amount
                        </TableCell>
                        <TableCell sx={headerCellSX} align="left">
                            Agrees
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bets?.map((bet) => (
                        <TableRow
                            key={`row-bet-id-${bet.id}-forecast-id-${bet.forecastId}`}
                        >
                            <TableCell align="left">
                                <Stack
                                    direction="row"
                                    gap={1}
                                    alignItems="center"
                                >
                                    <Address account={bet.bettor} />{" "}
                                    {selectedAccount?.toLowerCase() ===
                                        bet.bettor.toLowerCase() && (
                                        <Typography>(You)</Typography>
                                    )}
                                </Stack>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="bold">
                                    {formatCurrency({
                                        valueToConvert: BigInt(bet.amount),
                                        rate,
                                        currencySymbol: CURRENCY_SYMBOL.USD,
                                        toFixed: 2,
                                    })}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography
                                    color={
                                        bet.type === BetType.AGREE
                                            ? "success.main"
                                            : "error.main"
                                    }
                                >
                                    {bet.type === BetType.AGREE ? "Yes" : "No"}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isLoading && (
                <CircularProgress size={25} sx={{ alignSelf: "center" }} />
            )}
            {!isLoading && !bets?.length && (
                <Typography textAlign="center">
                    No bets {!forecast.isSettled ? "yet" : ""}
                </Typography>
            )}
            {totalBets > bets.length ? (
                <Button onClick={async () => fetchForecastBets(page + 1)}>
                    Load more bets
                </Button>
            ) : null}
        </Stack>
    );
}
