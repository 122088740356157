import { ContractType } from "src/hooks/useContract";
import { BetType, ForecastDirection } from "src/services/api";

import {
    BET_TYPE_AGREE,
    BET_TYPE_DISAGREE,
    FORECAST_DIRECTION_ABOVE,
    FORECAST_DIRECTION_BELOW,
} from "src/constants";

export type PaginationParams = { offset: number; limit: number };
export const getForecasts = async (
    contract: ContractType,
    { offset, limit }: PaginationParams,
) => {
    return await contract.read.getForecasts([BigInt(offset), BigInt(limit)]);
};

export const getForecastsCount = async (contract: ContractType) => {
    return await contract.read.forecastCount();
};

export const getOpenForecastsCount = async (contract: ContractType) => {
    return await contract.read.getOpenForecastsCount();
};

export const getOpenForecasts = async (
    contract: ContractType,
    { offset, limit }: PaginationParams,
) => {
    return await contract.read.getOpenForecasts([
        BigInt(offset),
        BigInt(limit),
    ]);
};

export const getForecastById = async (
    contract: ContractType,
    forecastId: number,
) => {
    return await contract.read.getForecast([BigInt(forecastId)]);
};

export type CreateForecastParams = {
    projectId: number;
    direction: ForecastDirection;
    predictedFrequency: bigint;
    settlingDeadline: Date;
    amount: bigint;
};

export const createForecast = async (
    contract: ContractType,
    {
        projectId,
        direction,
        predictedFrequency,
        settlingDeadline,
        amount,
    }: CreateForecastParams,
) => {
    const params: [bigint, bigint, bigint, number] = [
        BigInt(projectId),
        predictedFrequency,
        BigInt(Math.floor(settlingDeadline.getTime() / 1_000)),
        direction === ForecastDirection.ABOVE
            ? FORECAST_DIRECTION_ABOVE
            : FORECAST_DIRECTION_BELOW,
    ];
    await contract.estimateGas.createForecast(params, { value: amount });
    return await contract.write.createForecast(params, { value: amount });
};

export type PlaceBetParams = {
    betType: BetType;
    amount: bigint;
    forecastId: number;
};
export const placeBet = async (
    contract: ContractType,
    { betType, amount, forecastId }: PlaceBetParams,
) => {
    const params: [bigint, number] = [
        BigInt(forecastId),
        betType === BetType.AGREE ? BET_TYPE_AGREE : BET_TYPE_DISAGREE,
    ];
    await contract.estimateGas.placeBet(params, { value: amount });
    return await contract.write.placeBet(params, { value: amount });
};

export const claimWinnings = async (
    contract: ContractType,
    { forecastId }: { forecastId: number },
) => {
    await contract.estimateGas.claimWinnings([BigInt(forecastId)]);
    return await contract.write.claimWinnings([BigInt(forecastId)]);
};
