import { format } from "date-fns";
import { ObjectToCamel } from "ts-case-convert";

import { BackendForecast, ForecastDirection } from "src/services/api";

import { CreateForecastFormParams } from "src/components/CreateForecast";

import { frequencyToBPS } from "src/utils/numberFormat";

export async function waitFor(
    intervalTime: number,
    releaseCallback = async () => true,
) {
    return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                const canBeReleased = await releaseCallback();

                if (canBeReleased) {
                    clearInterval(interval);
                    resolve();
                }
            } catch (e) {
                clearInterval(interval);
                reject(e);
            }
        }, intervalTime);
    });
}

type FormatPredictionParams = {
    forecast?: ObjectToCamel<BackendForecast>;
    rate?: number;
    forecastFormParams?: CreateForecastFormParams;
};
export const formatPrediction = ({
    forecast,
    rate,
    forecastFormParams,
}: FormatPredictionParams) => {
    let textStart, projectName, direction, predictedBps, date, endText;
    if (forecast && rate) {
        textStart = "Do you";
        projectName = forecast.project.name;
        direction = forecast.direction;
        predictedBps = `${frequencyToBPS(forecast.predictedFrequency)} bps`;
        date = forecast.settlingDeadline;
        endText = "?";
    }
    if (forecastFormParams && forecastFormParams.predictedBps) {
        textStart = "You";
        projectName = forecastFormParams.projectName;
        direction = forecastFormParams.direction;
        predictedBps = `${forecastFormParams.predictedBps} bps`;
        date = forecastFormParams.settlingDeadline;
        endText = ".";
    }
    if (
        textStart &&
        projectName &&
        direction &&
        predictedBps &&
        date &&
        endText
    ) {
        return `${textStart} think the mindshare of the project "${projectName}" will be ${direction === ForecastDirection.ABOVE ? "higher" : "lower"} than ${predictedBps} before ${format(new Date(date), "MMM dd 'at' hh:mm a")}${endText}`;
    } else {
        throw new Error("Bad input values");
    }
};
