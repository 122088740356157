import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from "recharts";
import { TreemapNode } from "recharts/types/util/types";

import {
    selectProjectChartData,
    selectProjectChartDataTotal,
} from "src/slices/projectChartDataSlice";
import {
    BackendProject,
    getProjects,
    selectProjectStatuses,
    selectProjectsByPage,
} from "src/slices/projectSlice";

import ProjectChart from "src/components/ProjectChart";

import { TOP_25_PROJECTS_COUNT } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/store";
import { frequencyToBPS } from "src/utils/numberFormat";

type TreemapData = {
    name: string;
    size: number;
    meta?: BackendProject;
    color: string;
};

const CustomizedContent = (props: unknown) => {
    const { x, y, width, height, name, meta } = props as TreemapNode &
        TreemapData;

    const [isHovered, setIsHovered] = useState(false);
    const chartData = useAppSelector(selectProjectChartData(meta?.id));
    const [wordChartSVGInnerHTML, setWordChartSVGInnerHTML] = useState<
        string | null
    >(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const theme = useTheme();
    const svgWidth = width - 40;
    const svgHeight = Math.min(height - 40, 50);
    let color = theme.palette.warning.main;

    const isIncreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].weightedRelativeFrequency >
            chartData[0].weightedRelativeFrequency;
    const isDecreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].weightedRelativeFrequency <
            chartData[0].weightedRelativeFrequency;

    if (isIncreasing) {
        color = theme.palette.success.main;
    }
    if (isDecreasing) {
        color = theme.palette.error.main;
    }
    useEffect(() => {
        // Need to wait the time the ref in set in WordChart component
        if (wordChartSVGInnerHTML === null) {
            const interval = setInterval(
                () =>
                    setWordChartSVGInnerHTML(
                        svgRef?.current?.innerHTML ?? null,
                    ),
                400,
            );

            return () => clearInterval(interval);
        }
    }, [wordChartSVGInnerHTML]);

    return (
        meta && (
            <g
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    cursor: isHovered ? "pointer" : undefined,
                }}
            >
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    rx={15}
                    ry={15}
                    style={{
                        fill: color,
                        stroke: "#121212",
                        strokeWidth: 5,
                        opacity: isHovered ? 0.7 : 0.5,
                    }}
                />
                {meta && (
                    <ProjectChart
                        projectId={meta.id}
                        svgRef={svgRef}
                        color={color}
                        width={svgWidth}
                        height={svgHeight}
                    />
                )}
                {wordChartSVGInnerHTML && (
                    <svg
                        dangerouslySetInnerHTML={{
                            __html: wordChartSVGInnerHTML,
                        }}
                        x={x + 20}
                        y={y + height - svgHeight - 20}
                        width={svgWidth}
                        height={svgHeight}
                        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                    />
                )}
                <clipPath id={`clip-for-${meta.id}`}>
                    <rect
                        width={svgWidth}
                        height={height - 30}
                        x={x + 20}
                        y={y + 15}
                    />
                </clipPath>
                <text
                    x={x + 20}
                    y={y + 30}
                    fill="#fff"
                    fontSize={18}
                    fontWeight="bold"
                    clipPath={`url(#clip-for-${meta.id})`}
                >
                    {name}
                </text>
                <text
                    x={x + 20}
                    y={y + 50}
                    fill="#fff"
                    fontSize={14}
                    clipPath={`url(#clip-for-${meta.id})`}
                >
                    {`${frequencyToBPS(meta.latestUpdateFrequency)} bps`}
                </text>
            </g>
        )
    );
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    const theme = useTheme();

    if (active && payload && payload.length) {
        const meta = payload[0].payload.meta as BackendProject;
        return (
            <Stack
                bgcolor={theme.palette.background.default}
                gap={1}
                padding={2}
                borderRadius={2}
            >
                <Typography fontWeight="bold">{meta.name}</Typography>
                <Stack direction="row">
                    <Typography>{`Mindshare (<24h):`}</Typography>
                    <Typography fontWeight="bold" ml={0.5}>
                        {frequencyToBPS(meta.latestUpdateFrequency)} bps
                    </Typography>
                </Stack>
            </Stack>
        );
    }

    return null;
};

export default function ProjectsTreemapChart() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const projects = useAppSelector(selectProjectsByPage(1));
    const statuses = useAppSelector(selectProjectStatuses);
    const projectChartDataTotal = useAppSelector(selectProjectChartDataTotal);

    useEffect(() => {
        dispatch(
            getProjects({
                page: 1,
                rowsPerPage: TOP_25_PROJECTS_COUNT,
                orderByKey: "latest_update_frequency",
                orderByDirection: "desc",
            }),
        );
    }, [dispatch]);

    // It could be that we get there with chartData for mine + all
    const chartDataByWordFetched = projectChartDataTotal >= projects.length;
    return (
        <>
            {statuses.getProjects == "idle" ||
            (statuses.getProjects == "succeeded" && projects.length === 0) ? (
                <Box width="100%" display="flex" justifyContent="center">
                    <Typography>
                        No projects in game yet. Stay tuned!
                    </Typography>
                </Box>
            ) : null}
            {statuses.getProjects === "loading" && (
                <Box width="100%" display="flex" justifyContent="center">
                    <CircularProgress sx={{ my: 2 }} />
                </Box>
            )}
            {statuses.getProjects === "failed" && (
                <Stack width="100%" alignItems="center" gap={1}>
                    <Typography>Failed loading words.</Typography>
                    <Button
                        onClick={() => {
                            dispatch(
                                getProjects({
                                    page: 1,
                                    rowsPerPage: TOP_25_PROJECTS_COUNT,
                                    orderByKey: "latest_update_frequency",
                                    orderByDirection: "desc",
                                }),
                            );
                        }}
                        sx={{ width: "fit-content" }}
                    >
                        Try again
                    </Button>
                </Stack>
            )}
            {statuses.getProjects === "succeeded" && chartDataByWordFetched && (
                <ResponsiveContainer
                    width="100%"
                    minHeight={isDownSm ? 1500 : 700}
                    style={{ paddingTop: 20 }}
                >
                    <Treemap
                        data={projects.map((project) => ({
                            name: project.name,
                            size: project.latestUpdateFrequency,
                            meta: project,
                        }))}
                        dataKey="size"
                        content={<CustomizedContent />}
                        isAnimationActive={false}
                        isUpdateAnimationActive={false}
                    >
                        <Tooltip content={<CustomTooltip />} />
                    </Treemap>
                </ResponsiveContainer>
            )}
        </>
    );
}
