import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";

import {
    selectProjectChartData,
    selectProjectChartDataError,
    selectProjectChartDataStatus,
} from "src/slices/projectChartDataSlice";

import { useAppSelector } from "src/store";
import { formatDate } from "src/utils/formatDate";
import { frequencyToBPS } from "src/utils/numberFormat";

export default function ProjectChart({
    projectId,
    svgRef,
    color,
    width = 150,
    height = 50,
}: {
    projectId: number;
    svgRef?: React.MutableRefObject<SVGSVGElement | null>;
    color?: string;
    width?: number;
    height?: number;
}) {
    const theme = useTheme();
    const chartData = useAppSelector(selectProjectChartData(projectId));
    const chartDataError = useAppSelector(
        selectProjectChartDataError(projectId),
    );
    const chartDataStatus = useAppSelector(
        selectProjectChartDataStatus(projectId),
    );

    const isIncreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].weightedRelativeFrequency >
            chartData[0].weightedRelativeFrequency;
    const isDecreasing =
        chartData &&
        chartData.length > 0 &&
        chartData[chartData.length - 1].weightedRelativeFrequency <
            chartData[0].weightedRelativeFrequency;

    let chartColor = color ?? theme.palette.warning.main;
    if (isIncreasing && !color) {
        chartColor = theme.palette.success.main;
    }
    if (isDecreasing && !color) {
        chartColor = theme.palette.error.main;
    }

    const minWeightedRelativeFrequency =
        chartData && chartData.length > 0
            ? Math.min(
                  ...chartData.map((data) =>
                      Number(data.weightedRelativeFrequency),
                  ),
              )
            : undefined;
    const maxWeightedRelativeFrequency =
        chartData && chartData.length > 0
            ? Math.max(
                  ...chartData.map((data) =>
                      Number(data.weightedRelativeFrequency),
                  ),
              )
            : undefined;

    const dataIsFetched = Boolean(chartDataStatus === "succeeded" && chartData);
    const enoughData = dataIsFetched && chartData && chartData?.length > 1;

    return (
        <Box sx={{ flexGrow: 1 }}>
            {["loading", "idle"].includes(chartDataStatus) && (
                <Skeleton variant="rounded" width="150px" height="50px" />
            )}
            {chartDataStatus === "failed" && chartDataError && (
                <Typography color="error">{chartDataError}</Typography>
            )}
            {dataIsFetched && enoughData && (
                <SparkLineChart
                    key={`chart-${projectId}`}
                    data={chartData.map(({ weightedRelativeFrequency }) =>
                        Number(weightedRelativeFrequency),
                    )}
                    xAxis={{
                        scaleType: "time",
                        data: chartData.map(({ date }) => new Date(date)),
                        valueFormatter: formatDate,
                        min: new Date(chartData[0].date),
                        max: new Date(chartData[chartData.length - 1].date),
                    }}
                    yAxis={{
                        max: maxWeightedRelativeFrequency,
                        min: minWeightedRelativeFrequency,
                    }}
                    valueFormatter={(value) =>
                        `${frequencyToBPS(value ?? 0)} bps`
                    }
                    height={height}
                    width={width}
                    showTooltip
                    showHighlight
                    colors={[chartColor]}
                    ref={svgRef}
                />
            )}
            {dataIsFetched && !enoughData && <Typography>-</Typography>}
        </Box>
    );
}
