import { Button, ButtonProps } from "@mui/material";
import { useState } from "react";

import { BetType } from "src/services/api";
import { Forecast } from "src/slices/forecastSlice";

import BetModal from "src/components/BetModal";

export default function BetButton({
    forecast,
    betType,
    ...rest
}: ButtonProps & {
    forecast: Forecast;
    betType: BetType;
}) {
    const [isBetModalOpen, setIsBetModalOpen] = useState(false);

    const cannotBet =
        new Date(forecast.bettingDeadline) < new Date() || forecast.isSettled;
    const label = betType === BetType.AGREE ? "YES" : "NO";
    const color = betType === BetType.AGREE ? "success" : "error";
    return (
        <>
            <Button
                variant={
                    !forecast.userAddressBetsData?.[betType].amount
                        ? "contained"
                        : "outlined"
                }
                color={color}
                onClick={() => setIsBetModalOpen(true)}
                disabled={cannotBet}
                {...rest}
            >
                {Math.round(forecast.betsData?.[betType].amountPercentage ?? 0)}
                % {label}
            </Button>
            {isBetModalOpen && (
                <BetModal
                    forecast={forecast}
                    betType={betType}
                    open={isBetModalOpen}
                    onClose={() => setIsBetModalOpen(false)}
                />
            )}
        </>
    );
}
