import { useCallback, useEffect, useState } from "react";
import { Address } from "viem";

import usePublicClient from "src/hooks/usePublicClient";

// roughly 1 USD in Base ETH in 16/01/2024
// should cover the gas fee of most transactions
export const LOW_BALANCE_THRESHOLD = 300000000000000n;

type UseBalanceParams = {
    address: Address | null;
    chainId?: string;
    fetchInterval?: number | null;
};

export default function useBalance({
    address,
    chainId,
    fetchInterval = null, // interval value if need to fetch the data periodically
}: UseBalanceParams) {
    const [balance, setBalance] = useState<bigint | null>(null);
    const publicClient = usePublicClient(chainId);

    const fetchBalance = useCallback(
        async (address: Address) => {
            try {
                const balance = await publicClient.getBalance({ address });
                setBalance(balance);
                return balance;
            } catch (e) {
                console.error(`Cannot get balance from ${address}`, e);
            }
        },
        [publicClient, setBalance],
    );

    useEffect(() => {
        if (address) {
            fetchBalance(address);
            let interval: NodeJS.Timeout | undefined;
            if (fetchInterval) {
                interval = setInterval(
                    () => fetchBalance(address),
                    fetchInterval,
                );
            }
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        } else {
            console.warn("No address provided, not doing anything");
        }
    }, [address, fetchBalance, fetchInterval]);

    return {
        balance,
        refetch: () => (address ? fetchBalance(address) : undefined),
    };
}
