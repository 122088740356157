import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xxs: true;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: ["Comic Sans MS", "Comic Sans", "cursive"].join(","),
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#45F0DF",
        },
        secondary: {
            main: "#515050",
        },
        success: {
            main: "#01ff94",
            contrastText: "#000000",
        },
        error: {
            main: "#f04242",
            contrastText: "#000000",
        },
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 500,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                enterTouchDelay: 10,
                leaveTouchDelay: 10000,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    fontWeight: "bold",
                    textTransform: "none",
                    borderRadius: 15,
                    ...(ownerState.variant === "outlined"
                        ? {
                              borderWidth: "2px",
                          }
                        : {}),
                }),
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    "&:last-of-type": {
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                    },
                    "&:first-of-type": {
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                    },
                },
            },
        },
    },
});

export default theme;
