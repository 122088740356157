import {
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    getForecastsTypesCounts,
    selectUserForecastsTypesCounts,
} from "src/slices/forecastSlice";

import { PATHS } from "src/constants";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

export default function ForecastsSelectionToggle() {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userForecastTypesCounts = useAppSelector(
        selectUserForecastsTypesCounts,
    );
    const { selectedAccount } = useContext(WalletContext);

    const handleChange = (
        _event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        if (newValue === "mine") {
            navigate(`${PATHS.LANDING}#my-forecasts`, {
                replace: true,
            });
        } else {
            navigate(PATHS.LANDING, {
                replace: true,
            });
        }
    };

    useEffect(() => {
        if (selectedAccount) {
            dispatch(
                getForecastsTypesCounts({
                    userAddress: selectedAccount ?? undefined,
                    onlyUserForecasts: true,
                }),
            );
        }
    }, [dispatch, selectedAccount]);

    const control = {
        value: hash === "#my-forecasts" ? "mine" : "all",
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <Stack direction="row">
            <ToggleButtonGroup size="small" {...control}>
                <ToggleButton value="all" key="all" color="primary">
                    <Typography>All forecasts</Typography>
                </ToggleButton>
                <ToggleButton
                    color="primary"
                    value="mine"
                    key="mine"
                    disabled={
                        userForecastTypesCounts.all === 0 || !selectedAccount
                    }
                >
                    <Typography>
                        Mine ({userForecastTypesCounts.all})
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}
