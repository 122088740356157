import axios, { AxiosError } from "axios";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { Address } from "viem";

import { BackendProject } from "src/slices/projectSlice";

import { DEFAULT_ROWS_PER_PAGE, TOP_25_PROJECTS_COUNT } from "src/constants";

const apiAxiosInstance = axios.create({
    baseURL: `${import.meta.env.VITE_BACKEND_HOST}/api`,
});

// object to store ongoing requests cancel tokens
const pendingRequests = new Map();

// next we set up the Request Interceptor, this logic triggers
// before each request that we send
apiAxiosInstance.interceptors.request.use(
    (config) => {
        // generate an identifier for each request
        const requestIdentifier = `${config.url}_${config.method}_${JSON.stringify(config.params)}`;

        // check if there is already a pending request with the same identifier
        if (pendingRequests.has(requestIdentifier)) {
            const cancelTokenSource = pendingRequests.get(requestIdentifier);
            // cancel the previous request
            cancelTokenSource.cancel("Cancelled due to new request");
        }

        // create a new CancelToken
        const newCancelTokenSource = axios.CancelToken.source();
        config.cancelToken = newCancelTokenSource.token;

        // store the new cancel token source in the map
        pendingRequests.set(requestIdentifier, newCancelTokenSource);

        return config;
    },
    (error) => {
        // return the error if the request fails
        return Promise.reject(error);
    },
);

// here we set up the Response Interceptor, this logic triggers
// before each response from the server comes
apiAxiosInstance.interceptors.response.use(
    (response) => {
        // remove completed request from pending map
        const requestIdentifier = `${response.config.url}_${response.config.method}_${JSON.stringify(response.config.params)}`;
        pendingRequests.delete(requestIdentifier);
        return response;
    },
    (error) => {
        // remove failed request from pending map
        if (error.config) {
            const requestIdentifier = `${error.config.url}_${error.config.method}_${JSON.stringify(error.config.params)}`;
            pendingRequests.delete(requestIdentifier);
        }
        return Promise.reject(error);
    },
);

export async function isBackendAvailable() {
    try {
        const response = await apiAxiosInstance.get(`/`);
        return response.data.success;
    } catch (_error: unknown) {
        const error = _error as AxiosError;
        if (error.message !== "Cancelled due to new request") {
            console.error("Error fetching bakcend health check", error.message);
            return false;
        }
    }
}

export async function getCurrencyRate() {
    try {
        const response = await apiAxiosInstance.get<{ rate: number }>(
            `/currency-rate`,
        );

        return response.data["rate"];
    } catch (_error: unknown) {
        const error = _error as AxiosError;
        if (error.message !== "Cancelled due to new request") {
            console.error("Error fetching currency rate", error.message);
            throw error;
        }
    }
}

export async function getSlidingTimeWindowSeconds() {
    const response = await apiAxiosInstance.get<{
        sliding_time_window_seconds: number;
    }>(`/sliding-time-window`);

    return response.data["sliding_time_window_seconds"];
}

export type GetForecastsStatusCountsResponse = {
    counts: {
        all: number;
        open_for_bet: number;
        settled: number;
        wait_settle: number;
        user: number;
    };
};

export async function getForecastsTypesCounts({
    userAddress,
    onlyUserForecasts,
}: Pick<GetForecastsParams, "userAddress" | "onlyUserForecasts">) {
    const response =
        await apiAxiosInstance.get<GetForecastsStatusCountsResponse>(
            `/forecasts/types-counts`,
            {
                params: objectToSnake({
                    userAddress,
                    ...(onlyUserForecasts ? { onlyUserForecasts } : {}),
                }),
            },
        );
    return objectToCamel(response.data.counts);
}

type BetResume = {
    amount: number;
    amount_percentage: number;
};

export enum ForecastDirection {
    ABOVE = "above",
    BELOW = "below",
}

export enum BetType {
    AGREE = "agree",
    DISAGREE = "disagree",
}

type BetsData = {
    total_amount: number;
    [BetType.AGREE]: BetResume;
    [BetType.DISAGREE]: BetResume;
};
type UserBetsData = BetsData & {
    bets_not_claimed: number;
    potential_winnings: number; // before claiming
    winnings: number; // after claiming
};

export type BackendForecast = {
    id: number;
    project: Pick<
        BackendProject,
        "id" | "name" | "words" | "latestUpdateFrequency"
    >;
    predicted_frequency: number;
    betting_deadline: string;
    settling_deadline: string;
    settlement_frequency: number | null;
    creator: Address;
    direction: ForecastDirection;
    is_settled: boolean;
    outcome: boolean;
    bets_data: BetsData | null;
    user_address_bets_data: UserBetsData | null;
};

type GetForecastsOrderBy =
    | "created_at"
    | "settling_deadline"
    | "direction"
    | "predicted_frequency"
    | "project_name"
    | "total_amount";

export type GetForecastsParams = {
    page: number;
    rowsPerPage: number;
    userAddress?: string | null;
    orderByKey: GetForecastsOrderBy;
    orderByDirection: "asc" | "desc";
    status: "all" | "open_for_bet" | "settled" | "wait_settle";
    onlyUserForecasts: boolean;
};

export async function getForecasts({
    page = 1,
    rowsPerPage = DEFAULT_ROWS_PER_PAGE,
    userAddress = null,
    orderByKey = "created_at",
    orderByDirection = "desc",
    status = "open_for_bet",
    onlyUserForecasts = false,
}: GetForecastsParams) {
    const response = await apiAxiosInstance.get<{
        items: BackendForecast[];
        total: number;
        page: number;
        rows_per_page: number;
        total_pages: number;
    }>(`/forecasts`, {
        params: objectToSnake({
            page,
            rowsPerPage,
            userAddress,
            orderByKey,
            orderByDirection,
            status,
            ...(onlyUserForecasts ? { onlyUserForecasts } : {}),
        }),
    });
    return objectToCamel(response.data);
}

export type GetForecastByIdParams = Pick<GetForecastsParams, "userAddress"> & {
    forecastId: number;
};
export async function getForecastById({
    userAddress,
    forecastId,
}: GetForecastByIdParams) {
    const response = await apiAxiosInstance.get<{ data: BackendForecast }>(
        `/forecasts/${forecastId}`,
        {
            params: objectToSnake({
                userAddress,
            }),
        },
    );
    return objectToCamel(response.data.data);
}

export type BackendBet = {
    id: number;
    forecast_id: number;
    bettor: Address;
    type: BetType;
    amount: number;
    claimed: boolean;
    share: number;
};

export type GetBetsByForecastIdParams = {
    forecastId: number;
    page: number;
    rowsPerPage: number;
};
export async function getBetsByForecastId({
    page,
    rowsPerPage,
    forecastId,
}: GetBetsByForecastIdParams) {
    const response = await apiAxiosInstance.get<{
        items: BackendBet[];
        total: number;
        page: number;
        rows_per_page: number;
        total_pages: number;
    }>(`/forecasts/${forecastId}/bets`, {
        params: objectToSnake({
            rowsPerPage,
            page,
        }),
    });
    return objectToCamel(response.data);
}

type GetProjectsOrderBy = "name" | "price" | "latest_update_frequency";

export type GetProjectsParams = {
    page: number;
    rowsPerPage: number;
    term?: string | null;
    orderByKey?: GetProjectsOrderBy;
    orderByDirection?: "asc" | "desc";
};

export async function getProjects({
    page = 1,
    rowsPerPage = TOP_25_PROJECTS_COUNT,
    term = null,
    orderByKey = "latest_update_frequency",
    orderByDirection = "desc",
}: GetProjectsParams) {
    const response = await apiAxiosInstance.get<{
        items: BackendProject[];
        total: number;
        page: number;
        rows_per_page: number;
        total_pages: number;
    }>(`/projects`, {
        params: objectToSnake({
            page,
            rowsPerPage,
            term,
            orderByKey,
            orderByDirection,
        }),
    });
    return objectToCamel(response.data);
}

export async function getChartsData({ projectIds }: { projectIds: number[] }) {
    const response = await apiAxiosInstance.get<{
        data: Array<{
            project_id: number;
            name: string;
            frequencies: Array<{
                weighted_relative_frequency: number;
                date: string;
            }>;
        }>;
    }>(`/projects/charts`, {
        params: objectToSnake({
            projectIds: projectIds?.join(",") ?? null,
        }),
    });

    return objectToCamel(response.data);
}
