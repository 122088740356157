import { Box, Typography } from "@mui/material";
import { useContext } from "react";

import { Forecast } from "src/slices/forecastSlice";

import ClaimWinningsButton from "src/components/ClaimWinningsButton";

import { CURRENCY_SYMBOL } from "src/constants";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { formatCurrency } from "src/utils/formatCurrency";

export default function WinningsCell({ forecast }: { forecast: Forecast }) {
    const { rate } = useContext(CurrencyRateContext);

    if (!forecast.userAddressBetsData) {
        // not connected | no current user bets
        return (
            <Box display="flex" justifyContent="center">
                <Typography>-</Typography>
            </Box>
        );
    }
    if (
        !forecast.userAddressBetsData.potentialWinnings &&
        forecast.userAddressBetsData.betsNotClaimed
    ) {
        return (
            <Box display="flex" justifyContent="center">
                <Typography>💰$0</Typography>
            </Box>
        );
    }

    if (forecast.userAddressBetsData.winnings && rate) {
        return (
            <Box display="flex" justifyContent="center">
                <Typography>
                    💰
                    {formatCurrency({
                        valueToConvert: BigInt(
                            forecast.userAddressBetsData.winnings,
                        ),
                        rate,
                        currencySymbol: CURRENCY_SYMBOL.USD,
                        toFixed: 2,
                    })}
                </Typography>
            </Box>
        );
    }
    return forecast.userAddressBetsData.betsNotClaimed &&
        forecast.userAddressBetsData.potentialWinnings ? (
        <ClaimWinningsButton forecast={forecast} />
    ) : null;
}
