import MenuIcon from "@mui/icons-material/Menu";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import {
    AppBar,
    Box,
    Container,
    IconButton,
    Link as MaterialLink,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import ConnectButton from "src/components/ConnectButton";
import ForecastsSelectionToggle from "src/components/ForecastsSelectionToggle";

import { PATHS } from "src/constants";
import { WalletContext } from "src/contexts/WalletContext";

function Header() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const { selectedAccount } = useContext(WalletContext);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const items = [
        {
            name: "forecasts",
            component: <ForecastsSelectionToggle />,
            condition: Boolean(selectedAccount),
            shouldCloseMenu: true,
        },
        {
            name: "connection",
            component: <ConnectButton />,
            condition: true,
            shouldCloseMenu: true,
        },
    ];
    const displayedItemsNumber = items.filter(
        ({ condition }) => condition,
    ).length;

    return (
        <>
            <AppBar position="static" sx={{ marginBottom: "2rem" }}>
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1,
                        }}
                    >
                        <Stack
                            direction="row"
                            flex={1}
                            alignItems="center"
                            gap={1}
                            justifyContent="space-between"
                        >
                            <Link
                                to={PATHS.LANDING}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    variant="h4"
                                    component="h1"
                                    color="primary"
                                    style={{
                                        color: theme.palette.primary.main,
                                        alignItems: "center",
                                        fontWeight: 600,
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    forecast.words.fun
                                </Typography>
                            </Link>
                            <Stack direction="row" gap={2} mr={2}>
                                <MaterialLink
                                    href={import.meta.env.VITE_TELEGRAM_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <TelegramIcon />
                                </MaterialLink>
                                <MaterialLink
                                    href="https://x.com/wordsdotfun"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <XIcon />
                                </MaterialLink>
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                display: {
                                    xxs: "none",
                                    xs:
                                        displayedItemsNumber <= 2
                                            ? "flex"
                                            : "none",
                                    sm:
                                        displayedItemsNumber <= 3
                                            ? "flex"
                                            : "none",
                                    md: "flex",
                                },
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                {items.map(
                                    ({ component, condition, name }) =>
                                        condition && (
                                            <React.Fragment
                                                key={`item-${name}`}
                                            >
                                                {component}
                                            </React.Fragment>
                                        ),
                                )}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: {
                                    xxs: "flex",
                                    xs:
                                        displayedItemsNumber <= 2
                                            ? "none"
                                            : "flex",
                                    sm:
                                        displayedItemsNumber <= 3
                                            ? "none"
                                            : "flex",
                                    md: "none",
                                },
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                            >
                                {items.map(
                                    ({
                                        condition,
                                        component,
                                        name,
                                        shouldCloseMenu,
                                    }) =>
                                        condition && (
                                            <MenuItem
                                                key={`menu-item-${name}`}
                                                onClick={() =>
                                                    shouldCloseMenu &&
                                                    handleCloseNavMenu()
                                                }
                                            >
                                                {component}
                                            </MenuItem>
                                        ),
                                )}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default Header;
