import Autocomplete, {
    AutocompleteChangeReason,
    AutocompleteProps,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { getProjects } from "src/services/api";
import { BackendProject } from "src/slices/projectSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { useAppDispatch } from "src/store";

export default function SearchProjectAutocomplete({
    onChange,
    ...rest
}: Omit<
    AutocompleteProps<BackendProject, false, false, false>,
    "onChange" | "renderInput" | "options"
> & {
    onChange?: (value: BackendProject | null) => void;
}) {
    const [value, setValue] = useState<BackendProject | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<readonly BackendProject[]>([]);
    const [debouncedInputValue] = useDebounce(inputValue, 300);
    const [open, setOpen] = useState(false);

    const dispatch = useAppDispatch();

    const fetch = useMemo(
        () =>
            debounce(
                async (
                    request: { input: string },
                    callback: (results?: readonly BackendProject[]) => void,
                ) => {
                    try {
                        setLoading(true);
                        const results = await getProjects({
                            page: 1,
                            rowsPerPage: 20,
                            term: request.input,
                        });
                        callback(results.items);
                    } catch (_error) {
                        const error = _error as AxiosError;
                        console.error(error);
                        dispatch(
                            setSnackbarFeedback({
                                type: SeverityType.ERROR,
                                message: error.message,
                            }),
                        );
                    } finally {
                        setLoading(false);
                    }
                },
                400,
            ),
        [dispatch],
    );

    useEffect(() => {
        let active = true;
        if (debouncedInputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (debouncedInputValue !== value?.name) {
            fetch(
                { input: debouncedInputValue },
                (results?: readonly BackendProject[]) => {
                    if (active) {
                        let newOptions: readonly BackendProject[] = [];
                        if (value) {
                            newOptions = [value];
                        }
                        if (results) {
                            newOptions = [...newOptions, ...results];
                        }
                        setOptions(newOptions);
                    }
                },
            );
        }

        return () => {
            active = false;
        };
    }, [value, debouncedInputValue, fetch]);

    const handleOpen = () => {
        setOpen(true);
        if (!value) {
            (async () => {
                try {
                    setLoading(true);
                    const results = await getProjects({
                        page: 1,
                        rowsPerPage: 20,
                    });
                    setOptions(results.items);
                } catch (_error) {
                    const error = _error as AxiosError;
                    console.error(error);
                    dispatch(
                        setSnackbarFeedback({
                            type: SeverityType.ERROR,
                            message: error.message,
                        }),
                    );
                } finally {
                    setLoading(false);
                }
            })();
        }
    };

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };

    return (
        <Autocomplete
            {...rest}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
            }
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No project"
            onChange={(
                _event: React.SyntheticEvent,
                newValue: BackendProject | null,
                reason: AutocompleteChangeReason,
            ) => {
                if (reason === "clear") {
                    setOptions([]);
                    setInputValue("");
                    setValue(null);
                } else {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    onChange?.(newValue);
                }
            }}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            loading={loading}
        />
    );
}
