import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { ReadContractErrorType } from "viem";

import { getChartsData as getChartsDataFromBackend } from "src/services/api";

import { RootState } from "src/store";

export type ChartData = ObjectToCamel<{
    weighted_relative_frequency: number;
    date: string;
}>;
interface IProjectChartDataState {
    chartDataByProjectId: Record<number, ChartData[]>;
    statusesByProjectId: Record<
        number,
        "idle" | "loading" | "succeeded" | "failed"
    >; // by projectId
    errorByProjectId: Record<number, string | null>; // by projectId
}

const initialState: IProjectChartDataState = {
    chartDataByProjectId: {},
    statusesByProjectId: {},
    errorByProjectId: {},
};

// Thunk for fetching multiple chart data by project ids
export const getChartsDataByProjectIds = createAsyncThunk<
    Array<{
        projectId: number;
        frequencies: ChartData[];
    }>,
    { projectIds: number[] }
>(
    "projectChartData/getChartsDataByProjectIds",
    async (params: { projectIds: number[] }, thunkAPI) => {
        const { projectIds } = params;
        try {
            const data = await getChartsDataFromBackend({ projectIds });

            return data.data;
        } catch (_error: unknown) {
            const error = _error as AxiosError | ReadContractErrorType;
            console.warn("Error while fetching projects: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const projectChartDataSlice = createSlice({
    name: "projectChartData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getChartsDataByProjectIds.fulfilled,
                (state, { payload }) => {
                    payload.forEach((project) => {
                        state.statusesByProjectId[project.projectId] =
                            "succeeded";
                        state.errorByProjectId[project.projectId] = null;
                        state.chartDataByProjectId[project.projectId] =
                            project.frequencies;
                    });
                },
            )
            .addCase(getChartsDataByProjectIds.pending, (state, { meta }) => {
                const projectIds = meta.arg.projectIds;
                projectIds.forEach((projectId) => {
                    state.statusesByProjectId[projectId] = "loading";
                    state.errorByProjectId[projectId] = null;
                });
            })
            .addCase(
                getChartsDataByProjectIds.rejected,
                (state, { payload, meta }) => {
                    const projectIds = meta.arg.projectIds;
                    projectIds.forEach((projectId) => {
                        state.statusesByProjectId[projectId] = "failed";
                        state.errorByProjectId[projectId] = payload as string;
                    });
                },
            );
    },
});

const selectProjectChartDataState = (state: RootState) =>
    state.projectChartData;

export const selectProjectChartData = (projectId?: number) =>
    createSelector(selectProjectChartDataState, (state) => {
        return projectId ? state.chartDataByProjectId[projectId] : undefined;
    });

export const selectProjectChartDataTotal = createSelector(
    selectProjectChartDataState,
    (state) => {
        return Object.values(state.chartDataByProjectId).filter(Boolean).length;
    },
);

export const selectProjectChartDataStatus = (projectId: number) =>
    createSelector(selectProjectChartDataState, (state) => {
        return state.statusesByProjectId[projectId];
    });

export const selectProjectChartDataError = (projectId: number) =>
    createSelector(selectProjectChartDataState, (state) => {
        return state.errorByProjectId[projectId];
    });

export default projectChartDataSlice.reducer;
