import { Typography } from "@mui/material";

import { BetType } from "src/services/api";
import { Forecast } from "src/slices/forecastSlice";

export default function ForecastBetsPercentage({
    forecast,
    betType,
}: {
    forecast: Forecast;
    betType: BetType;
}) {
    return (
        <Typography color={betType === BetType.AGREE ? "success" : "error"}>
            {Math.round(forecast.betsData?.[betType].amountPercentage ?? 0)}%{" "}
            {betType === BetType.AGREE ? "YES" : "NO"}
        </Typography>
    );
}
