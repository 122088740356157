import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Box,
    Button,
    Container,
    Divider,
    Link,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    getSlidingTimeWindowSeconds,
    selectSlidingTimeWindowSeconds,
} from "src/slices/settingsSlice";

import CreateForecast from "src/components/CreateForecast";
import ForecastsList from "src/components/ForecastsList";
import ProjectsTreemapChart from "src/components/ProjectsTreemapChart";
import StyledModal from "src/components/StyledModal";

import { PATHS } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/store";

const CONTENT = [
    {
        title: "What is forecast.words.fun?",
        content:
            "Predict what topics will be trending on Crypto Twitter. You can make your guesses and earn money if you're correct.",
    },
    {
        title: "Where does the data come from?",
        content:
            "Forecast.words.fun is built on top of Words.fun which collects data by analyzing discussions on Crypto Twitter. It sets a real-time floor price for each word based on its popularity in conversation. This allows users to buy words early and sell them as trends grow.",
    },
    {
        title: "How are funds distributed after a forecast is settled?",
        content:
            "Once a forecast is settled, all the money is shared among those who made correct predictions, based on how much they initially invested. If you bet more, you get a bigger share, rewarding smart predictions and taking risks.",
    },
    {
        title: "What do I earn if others make forecasts on the word I own?",
        content:
            "When a forecast is settled, the word owners share 5% of the total winnings.",
    },
];

const Landing = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { hash } = useLocation();

    let defaultTab = "top25";
    if (hash === "#my-forecasts" || hash === "#list") {
        defaultTab = "forecasts";
    }

    const [activeTab, setActiveTab] = useState<"top25" | "forecasts">(
        defaultTab as "top25" | "forecasts",
    );
    const [readInstructions, setReadInstructions] = useState<boolean>(
        localStorage.getItem("readInstructions") === "true",
    );
    const [openInstructionsModal, setOpenInstructionsModal] =
        useState<boolean>(!readInstructions);
    const slidingTimeWindowSeconds = useAppSelector(
        selectSlidingTimeWindowSeconds,
    );

    const handleCloseInstructionsModal = () => {
        setOpenInstructionsModal(false);
        localStorage.setItem("readInstructions", "true");
        setReadInstructions(true);
    };

    useEffect(() => {
        if (
            (hash === "#my-forecasts" || hash === "#list") &&
            activeTab === "top25"
        ) {
            setActiveTab("forecasts");
        }
    }, [activeTab, hash]);

    useEffect(() => {
        if (!slidingTimeWindowSeconds) {
            dispatch(getSlidingTimeWindowSeconds());
        }
    }, [dispatch, slidingTimeWindowSeconds]);

    return (
        <Container
            maxWidth="xl"
            sx={{
                flex: 1,
                paddingBottom: 2,
            }}
        >
            <Stack gap={2} alignItems="center">
                <Link
                    sx={{
                        cursor: "pointer",
                        alignItems: "center",
                        display: "flex",
                    }}
                    onClick={() => {
                        setOpenInstructionsModal(true);
                    }}
                >
                    <HelpOutlineIcon color="primary" />
                    &nbsp;Remind me the rules
                </Link>
                {slidingTimeWindowSeconds && (
                    <CreateForecast
                        slidingTimeWindowSeconds={slidingTimeWindowSeconds}
                    />
                )}
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Tabs
                value={activeTab}
                onChange={(_, value) => {
                    setActiveTab(value);
                    if (
                        value === "top25" &&
                        (hash === "#my-forecasts" || hash === "#list")
                    ) {
                        navigate(PATHS.LANDING, {
                            replace: true,
                        });
                    }
                    if (!hash && value === "forecasts") {
                        navigate(`${PATHS.LANDING}#list`, {
                            replace: true,
                        });
                    }
                }}
            >
                <Tab value="top25" label="Top 25" />
                <Tab value="forecasts" label="Forecasts list" />
            </Tabs>
            {activeTab === "top25" && <ProjectsTreemapChart />}
            {activeTab === "forecasts" && <ForecastsList />}

            <StyledModal
                open={openInstructionsModal}
                aria-labelledby="instructions-modal"
                aria-describedby="game-instructions"
                onClose={handleCloseInstructionsModal}
            >
                <Box>
                    {CONTENT.map((content, index) => (
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }} key={index}>
                            <Typography variant="h6" component="div" mb={1}>
                                {content.title}
                            </Typography>
                            <Typography variant="body1" component="div">
                                {content.content}
                            </Typography>
                        </Paper>
                    ))}
                    <Button
                        variant="contained"
                        onClick={handleCloseInstructionsModal}
                    >
                        Let&apos;s play !
                    </Button>
                </Box>
            </StyledModal>
        </Container>
    );
};

export default Landing;
