import { LoadingButton } from "@mui/lab";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    ModalProps,
    Stack,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { WriteContractErrorType } from "viem";

import useBalance, { LOW_BALANCE_THRESHOLD } from "src/hooks/useBalance";
import { ContractType } from "src/hooks/useContract";
import { BetType } from "src/services/api";
import { PlaceBetParams, placeBet } from "src/services/forecastContract";
import { Forecast, getForecastById } from "src/slices/forecastSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import AmountInput from "src/components/AmountInput";
import StyledModal from "src/components/StyledModal";

import { CURRENCY_SYMBOL, MIN_BET_AMOUNT } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch } from "src/store";
import { formatPrediction, waitFor } from "src/utils/common";
import { formatCurrency, formatUSDToWei } from "src/utils/formatCurrency";

type BetModalProps = Omit<ModalProps, "children" | "onClose"> & {
    forecast: Forecast;
    onClose: () => void;
    betType: BetType;
};

export default function BetModal({
    forecast,
    betType,
    open,
    onClose,
}: BetModalProps) {
    const dispatch = useAppDispatch();
    const { selectedAccount } = useContext(WalletContext);
    const { rate } = useContext(CurrencyRateContext);
    const { contract } = useContext(ContractContext);
    const { balance } = useBalance({
        address: selectedAccount,
        fetchInterval: 2_000,
    });

    const [params, setParams] = useState<
        Partial<PlaceBetParams> & { amountUSD: string }
    >({
        forecastId: forecast.id,
        amountUSD: "",
        betType,
    });
    const [loading, setLoading] = useState(false);
    const [amountError, setAmountError] = useState<string | null>(null);

    const paramsAreValid =
        typeof params.forecastId === "number" &&
        params.betType &&
        params.amountUSD &&
        !amountError;

    const sendBet = async () => {
        let error;
        if (!contract) {
            error = "Contract is not defined";
        }
        if (!paramsAreValid) {
            error = "Parameters are not valid";
        }
        if (!rate) {
            error = "Need rate to convert $ to ETH";
        }
        if (error) {
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error,
                }),
            );
            return;
        }
        setLoading(true);
        try {
            await placeBet(
                contract as ContractType,
                {
                    ...params,
                    amount: formatUSDToWei({
                        rate,
                        valueToConvert: params.amountUSD,
                    }),
                } as PlaceBetParams,
            );
            await waitFor(2_000, async () => {
                const newForecast = await dispatch(
                    getForecastById({
                        forecastId: forecast.id,
                        userAddress: selectedAccount,
                    }),
                ).unwrap();
                return Boolean(
                    newForecast.userAddressBetsData &&
                        newForecast.userAddressBetsData.totalAmount >
                            (forecast.userAddressBetsData?.totalAmount ?? 0),
                );
            });
        } catch (_error) {
            const error = _error as WriteContractErrorType;
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error.message,
                }),
            );
        } finally {
            setLoading(false);
            setParams({
                forecastId: forecast.id,
                amountUSD: "",
            });
            onClose();
        }
    };

    return (
        <StyledModal
            open={open}
            onClose={!loading ? () => onClose() : undefined}
            aria-labelledby="place-bet-dialog-title"
            aria-describedby="place-bet-dialog-description"
        >
            <Stack>
                <DialogTitle id="place-bet-dialog-title">
                    You {params.betType} ?
                </DialogTitle>
                <DialogContent id="place-bet-dialog-description">
                    <Typography mb={2}>
                        {rate ? formatPrediction({ forecast, rate }) : ""}
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={2}
                    >
                        <AmountInput
                            showUnit
                            sx={{ flex: 2 }}
                            unit="$"
                            placeholder="amount"
                            value={params.amountUSD}
                            onChange={(event) => {
                                setAmountError(null);
                                const newAmountUSD = event.target.value;
                                setParams((prev) => ({
                                    ...prev,
                                    amountUSD: newAmountUSD,
                                }));
                                const amountETH = formatUSDToWei({
                                    rate,
                                    valueToConvert: newAmountUSD,
                                });
                                if (
                                    newAmountUSD !== "" &&
                                    Number(newAmountUSD) < MIN_BET_AMOUNT
                                ) {
                                    setAmountError(
                                        `Min bet amount is $${MIN_BET_AMOUNT}`,
                                    );
                                } else if (
                                    !balance ||
                                    amountETH + LOW_BALANCE_THRESHOLD > balance
                                ) {
                                    setAmountError(
                                        "Not enought funds to be able to complete the bet",
                                    );
                                }
                            }}
                            error={Boolean(amountError)}
                            helperText={amountError}
                        />
                        {rate && (
                            <Typography sx={{ flex: 1 }}>
                                (
                                {params.amountUSD
                                    ? formatCurrency({
                                          valueToConvert: formatUSDToWei({
                                              rate,
                                              valueToConvert: params.amountUSD,
                                          }),
                                          currencySymbol: CURRENCY_SYMBOL.ETH,
                                          rate,
                                          toFixed: 6,
                                      })
                                    : "0 ETH"}
                                )
                            </Typography>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={sendBet}
                        disabled={loading || !paramsAreValid}
                        loading={loading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </StyledModal>
    );
}
