import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import ConnectedSnackbar from "src/components/ConnectedSnackbar";

import App from "src/App.tsx";
import { ContractProvider } from "src/contexts/ContractsContext.tsx";
import { CurrencyRateProvider } from "src/contexts/CurrencyRateContext";
import { WalletProvider } from "src/contexts/WalletContext.tsx";
import "src/index.css";
import "src/log.ts";
import { store } from "src/store.ts";
import theme from "src/theme.ts";
import { isSerializable } from "src/utils/isSerializable";

const backendHostRegexp = new RegExp(`${import.meta.env.VITE_BACKEND_HOST}/.*`);
if (import.meta.env.VITE_SENTRY_ENV !== "dev") {
    Sentry.init({
        dsn: "TODO",
        environment: import.meta.env.VITE_SENTRY_ENV || "unkown",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: ["localhost", backendHostRegexp],
        // Performance Monitoring
        tracesSampleRate: 0.5, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: ["User rejected the request"],
        beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
            const exception = hint.originalException as unknown;

            if (
                hint?.mechanism?.type == "onunhandledrejection" &&
                event?.exception?.values?.[0]
            ) {
                let exceptionValue = "Unhandled rejection of promise with";
                exceptionValue += isSerializable(
                    hint.originalException as object,
                )
                    ? ` argument: ${JSON.stringify(hint.originalException)}`
                    : ` keys (argument is not serializable): ${Object.keys(
                          hint.originalException as object,
                      ).join()}`;
                exceptionValue += isSerializable(hint.data)
                    ? ` and data: ${JSON.stringify(hint.data)}`
                    : ` and data keys: ${Object.keys(hint.data).join()}`;
                event.exception.values[0] = {
                    mechanism: hint?.mechanism as never,
                    type: "UnhandledRejection",
                    value: exceptionValue,
                };
            }

            // Exclude no internet connection from being reported to Sentry
            if (
                exception instanceof AxiosError &&
                (exception?.code === AxiosError.ERR_NETWORK ||
                    /No internet connection/.test(exception?.message))
            ) {
                return null;
            }

            return event;
        },
    });
}

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <WalletProvider>
                        <ContractProvider>
                            <CurrencyRateProvider>
                                <Router>
                                    <App />
                                </Router>
                                <ConnectedSnackbar />
                            </CurrencyRateProvider>
                        </ContractProvider>
                    </WalletProvider>
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    </StrictMode>,
);
