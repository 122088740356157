import {
    Box,
    Button,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tabs,
    Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toCamel } from "ts-case-convert";

import {
    BetType,
    ForecastDirection,
    GetForecastsParams,
} from "src/services/api";
import {
    getForecasts,
    getForecastsTypesCounts,
    selectForecastsByStatusAndPage,
    selectForecastsStatuses,
    selectForecastsTypesCounts,
    selectUserForecastsTypesCounts,
} from "src/slices/forecastSlice";

import BetButton from "src/components/BetButton";
import ForecastBetsPercentage from "src/components/ForecastBetsPercentage";
import ForecastDetailModal from "src/components/ForecastDetailModal";
import WinningsCell from "src/components/WinningsCell";

import { CURRENCY_SYMBOL, DEFAULT_ROWS_PER_PAGE, PATHS } from "src/constants";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatDate } from "src/utils/formatDate";
import { frequencyToBPS } from "src/utils/numberFormat";

type OrderByKey = GetForecastsParams["orderByKey"];
type Order = "asc" | "desc";

interface HeadCell {
    key: OrderByKey | "bets" | "winnings" | "current_frequency";
    label: string;
    sortable: boolean;
    align: TableCellProps["align"];
}

const defaultHeadCells: readonly HeadCell[] = [
    {
        key: "project_name",
        label: "Project",
        sortable: true,
        align: "left",
    },
    {
        key: "current_frequency",
        label: "Current mindshare",
        sortable: true,
        align: "right",
    },
    {
        key: "predicted_frequency",
        label: "Estimated mindshare",
        sortable: true,
        align: "right",
    },
    {
        key: "settling_deadline",
        label: "Deadline",
        sortable: true,
        align: "right",
    },
    {
        key: "total_amount",
        label: "Volume",
        sortable: true,
        align: "right",
    },
    {
        key: "bets",
        label: "Forecast",
        sortable: false,
        align: "center",
    },
];

const extraHeadCells: readonly HeadCell[] = [
    {
        key: "winnings",
        label: "Winnings",
        sortable: false,
        align: "center",
    },
];

const stickyColumnStyle = {
    position: "sticky",
    left: 0,
    background: "#1E1E1E",
    borderRight: "1px solid rgba(81, 81, 81, 1) !important",
    zIndex: "10 !important",
};

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: HeadCell["key"],
    ) => void;
    orderByDirection: Order;
    orderByKey: Omit<OrderByKey, "frequency_trend">;
    headCells: readonly HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { orderByDirection, orderByKey, onRequestSort, headCells } = props;
    const createSortHandler =
        (property: HeadCell["key"]) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) =>
                    headCell.sortable ? (
                        <TableCell
                            key={headCell.key as React.Key}
                            sortDirection={
                                orderByKey === headCell.key
                                    ? orderByDirection
                                    : false
                            }
                            align={headCell.align}
                            sx={{
                                ...(index === 0 ? stickyColumnStyle : {}),
                                fontWeight: "bold",
                                padding: 0.5,
                                textAlign: "center",
                            }}
                        >
                            <TableSortLabel
                                active={orderByKey === headCell.key}
                                direction={
                                    orderByKey === headCell.key
                                        ? orderByDirection
                                        : "asc"
                                }
                                onClick={createSortHandler(headCell.key)}
                            >
                                <Typography flex={1}>
                                    {headCell.label}
                                </Typography>
                                {orderByKey === headCell.key ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {orderByDirection === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ) : (
                        <TableCell
                            key={headCell.key as React.Key}
                            align={headCell.align}
                        >
                            {headCell.label}
                        </TableCell>
                    ),
                )}
            </TableRow>
        </TableHead>
    );
}

type RowsPerPage = 10 | 25 | 50;
const rowsPerPageOptions: RowsPerPage[] = [10, DEFAULT_ROWS_PER_PAGE, 50];

export default function ForecastsList() {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const dispatch = useAppDispatch();
    const { selectedAccount } = useContext(WalletContext);
    const forecastTypesCounts = useAppSelector(selectForecastsTypesCounts);
    const userForecastTypesCounts = useAppSelector(
        selectUserForecastsTypesCounts,
    );
    const { rate } = useContext(CurrencyRateContext);
    const onlyUserForecasts = hash === "#my-forecasts";

    const [detailModalForecastId, setDetailModalForecastId] = useState<
        number | null
    >(null);
    const [queryParams, setQueryParams] = useState<GetForecastsParams>({
        orderByDirection: "asc" as Order,
        orderByKey: "settling_deadline" as OrderByKey,
        page: 1,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        status: "open_for_bet",
        onlyUserForecasts,
        userAddress: selectedAccount,
    });
    const forecasts = useAppSelector(
        selectForecastsByStatusAndPage({
            status: queryParams.status,
            page: queryParams.page,
        }),
    );
    const statuses = useAppSelector(selectForecastsStatuses);

    useEffect(() => {
        dispatch(
            getForecastsTypesCounts({
                userAddress: selectedAccount ?? undefined,
                onlyUserForecasts,
            }),
        );
    }, [dispatch, onlyUserForecasts, selectedAccount]);

    const items = forecasts;
    const counts = onlyUserForecasts
        ? userForecastTypesCounts
        : forecastTypesCounts;
    const totalItems = counts[toCamel(queryParams.status)];

    // Redirect if #my-forecasts is not applicable
    useEffect(() => {
        if (!selectedAccount && onlyUserForecasts) {
            navigate(PATHS.LANDING, { replace: true });
        } else {
            setQueryParams((prev) => ({
                ...prev,
                onlyUserForecasts,
            }));
        }
    }, [selectedAccount, navigate, onlyUserForecasts]);

    useEffect(() => {
        dispatch(getForecasts(queryParams));
    }, [dispatch, queryParams, totalItems]);

    const handleRequestSort = (
        _event: React.MouseEvent<unknown>,
        property: HeadCell["key"],
    ) => {
        const isAsc =
            queryParams.orderByKey === property &&
            queryParams.orderByDirection === "asc";
        setQueryParams((prev) => ({
            ...prev,
            orderByDirection: isAsc ? "desc" : "asc",
            orderByKey: property as OrderByKey,
            page: 1,
        }));
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setQueryParams((prev) => ({
            ...prev,
            page: newPage + 1,
        }));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setQueryParams((prev) => ({
            ...prev,
            rowsPerPage: parseInt(event.target.value) as RowsPerPage,
            page: 1,
        }));
    };

    let headCells: readonly HeadCell[] = defaultHeadCells;
    if (queryParams.status === "settled") {
        headCells = [
            ...defaultHeadCells.map((cell) => ({
                ...cell,
                ...(cell.key === "current_frequency"
                    ? {
                          label: "Settlement mindshare",
                      }
                    : {}),
            })),
            ...extraHeadCells,
        ];
    }

    return (
        <Stack gap={2}>
            <Tabs
                value={queryParams.status}
                onChange={(_, value) => {
                    setQueryParams((prev) => ({
                        ...prev,
                        status: value,
                    }));
                }}
            >
                <Tab
                    value="open_for_bet"
                    label={`Open for bet (${counts["openForBet"]})`}
                />
                <Tab
                    value="wait_settle"
                    label={`Waiting settlement (${counts["waitSettle"]})`}
                />
                <Tab value="settled" label={`Settled (${counts["settled"]})`} />
            </Tabs>
            <TableContainer component={Paper}>
                <Table
                    aria-label="simple table"
                    sx={{
                        borderCollapse: "separate",
                        "tr:hover td:not(.action)": {
                            cursor: "pointer",
                            opacity: 0.5,
                        },
                    }}
                >
                    <EnhancedTableHead
                        orderByDirection={queryParams.orderByDirection}
                        orderByKey={queryParams.orderByKey}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        {statuses.getForecasts === "loading" ? (
                            <>
                                {Array.from({
                                    length: Math.min(
                                        queryParams.rowsPerPage,
                                        totalItems ?? queryParams.rowsPerPage,
                                    ),
                                }).map((_, index) => (
                                    <TableRow key={`fake-row-${index}`}>
                                        {Array.from({
                                            length: headCells.length,
                                        }).map((_, i) => (
                                            <TableCell
                                                align="center"
                                                key={`fake-cell-${index}-${i}`}
                                            >
                                                <Skeleton
                                                    variant="text"
                                                    width="80%"
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </>
                        ) : null}
                        {statuses.getForecasts == "idle" ||
                        (statuses.getForecasts == "succeeded" &&
                            items.length === 0) ? (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    <Typography>
                                        No forecast yet. Stay tuned!
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {statuses.getForecasts == "failed" &&
                        items.length == 0 ? (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    <Typography>
                                        Failed loading forecasts.
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            dispatch(getForecasts(queryParams));
                                        }}
                                    >
                                        Try again
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {statuses.getForecasts == "succeeded" &&
                            items.length > 0 &&
                            rate &&
                            items.map((row) => (
                                <TableRow
                                    key={`${row.project.name}-${row.id}`}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    onClick={() =>
                                        setDetailModalForecastId(row.id)
                                    }
                                >
                                    <TableCell
                                        align="left"
                                        sx={stickyColumnStyle}
                                    >
                                        <Typography fontWeight="bold">
                                            {row.project.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {queryParams.status === "settled"
                                            ? `${
                                                  row.settlementFrequency !==
                                                  null
                                                      ? frequencyToBPS(
                                                            row.settlementFrequency,
                                                        )
                                                      : "-"
                                              } bps`
                                            : `${frequencyToBPS(
                                                  row.project
                                                      .latestUpdateFrequency,
                                              )} bps`}
                                        {queryParams.status === "settled" &&
                                        row.outcome
                                            ? " 🎉"
                                            : ""}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.direction ===
                                        ForecastDirection.ABOVE
                                            ? ">= "
                                            : "< "}
                                        {`${frequencyToBPS(
                                            row.predictedFrequency,
                                        )} bps`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {formatDate(
                                            new Date(row.settlingDeadline),
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.betsData?.totalAmount
                                            ? formatCurrency({
                                                  valueToConvert: BigInt(
                                                      row.betsData.totalAmount,
                                                  ),
                                                  rate,
                                                  currencySymbol:
                                                      CURRENCY_SYMBOL.USD,
                                                  toFixed: 2,
                                              })
                                            : "$0"}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={
                                            queryParams.status ===
                                            "open_for_bet"
                                                ? "action"
                                                : undefined
                                        }
                                        onClick={(ev) => {
                                            if (
                                                queryParams.status ===
                                                "open_for_bet"
                                            ) {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                            }
                                        }}
                                    >
                                        <Stack direction="row" gap={1}>
                                            <Box
                                                display="flex"
                                                justifyContent="right"
                                                flex={1}
                                            >
                                                {queryParams.status !==
                                                "open_for_bet" ? (
                                                    <ForecastBetsPercentage
                                                        forecast={row}
                                                        betType={BetType.AGREE}
                                                    />
                                                ) : (
                                                    <BetButton
                                                        forecast={row}
                                                        betType={BetType.AGREE}
                                                    />
                                                )}
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="left"
                                                flex={1}
                                            >
                                                {queryParams.status !==
                                                "open_for_bet" ? (
                                                    <ForecastBetsPercentage
                                                        forecast={row}
                                                        betType={
                                                            BetType.DISAGREE
                                                        }
                                                    />
                                                ) : (
                                                    <BetButton
                                                        forecast={row}
                                                        betType={
                                                            BetType.DISAGREE
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                    {queryParams.status === "settled" ? (
                                        <TableCell
                                            align="center"
                                            className="action"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                            }}
                                        >
                                            <WinningsCell
                                                key={`forecast-${row.id}`}
                                                forecast={row}
                                            />
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Forecasts per page"
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalItems ?? 0}
                rowsPerPage={queryParams.rowsPerPage}
                page={queryParams.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    "& .MuiTablePagination-spacer": {
                        order: 2,
                    },
                    "& .MuiTablePagination-selectLabel": {
                        order: 0,
                    },
                    "& .MuiTablePagination-select": {
                        order: 1,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        order: 3,
                    },
                    "& .MuiTablePagination-actions": {
                        order: 4,
                    },
                }}
            />
            {typeof detailModalForecastId === "number" && (
                <ForecastDetailModal
                    forecastId={detailModalForecastId}
                    open={typeof detailModalForecastId === "number"}
                    onClose={() => setDetailModalForecastId(null)}
                />
            )}
        </Stack>
    );
}
