import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, useStore } from "react-redux";

import forecastReducer from "src/slices/forecastSlice";
import projectChartDataReducer from "src/slices/projectChartDataSlice";
import projectReducer from "src/slices/projectSlice";
import settingsReducer from "src/slices/settingsSlice";
import snackbarFeedbackReducer from "src/slices/snackbarFeedbackSlice";

export const store = configureStore({
    reducer: {
        snackbarFeedback: snackbarFeedbackReducer,
        settings: settingsReducer,
        forecast: forecastReducer,
        project: projectReducer,
        projectChartData: projectChartDataReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
