import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import useContract from "src/hooks/useContract";
import { isBackendAvailable } from "src/services/api";

import FullscreenMessage from "src/components/FullscreenMessage";
import Header from "src/components/Header";

import { PATHS } from "src/constants";
import Error from "src/routes/Error";
import Landing from "src/routes/Landing";
import NotFound from "src/routes/NotFound";

function App() {
    const location = useLocation();
    const { contract, error, loading } = useContract();
    const [isBackendReady, setIsBackendReady] = useState<boolean | null>(null);
    const [reactGAInitialized, setReactGAInitialized] = useState(false);

    useEffect(() => {
        async function checkBackend() {
            const backendStatus = await isBackendAvailable();
            setIsBackendReady(backendStatus);
        }

        checkBackend();
    }, []);

    useEffect(() => {
        if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID !== "none") {
            ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID, {
                standardImplementation: true,
            });
            setReactGAInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (reactGAInitialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location, reactGAInitialized]);

    if (isBackendReady === false) {
        return (
            <FullscreenMessage>
                Backend is not ready. 👻
                <br />
                Either we have too much success and the server is overloaded
                <br />
                ... or intern might be fired.
            </FullscreenMessage>
        );
    }

    if (error) {
        return (
            <FullscreenMessage>
                Contract is not here. 👻
                <br />
                We're probably deploying an update full of surprises
                <br />
                ... or something went terribly wrong.
            </FullscreenMessage>
        );
    }

    if (loading || !contract?.address || isBackendReady === null) {
        return (
            <FullscreenMessage withLoader>
                Loading necessary bits...
            </FullscreenMessage>
        );
    }

    return (
        <>
            <Header />
            <Container>
                <Routes>
                    <Route
                        path="*"
                        element={
                            <Navigate to={PATHS.NOT_FOUND} replace={true} />
                        }
                    />
                    <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
                    <Route path={PATHS.ERROR} element={<Error />} />
                    <Route path={PATHS.LANDING} element={<Landing />} />
                </Routes>
            </Container>
        </>
    );
}

export default App;
