import { padNumber } from "src/utils/padNumber";

export const frequencyToPercentage = (frequency: number) => {
    return ((frequency / 1000000) * 100).toFixed(2);
};

export const frequencyToBPS = (frequency: number) => {
    return ((frequency / 10000) * 100).toFixed(2);
};

export const bpsToFrequency = (bps: number) => {
    return Math.floor((bps / 100) * 10000);
};

export const timeFormat = (remainingSeconds: number) => {
    const days = Math.floor(remainingSeconds / 86400);
    const hours = Math.floor((remainingSeconds % 86400) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;

    let formattedTime = "";
    if (days > 0) {
        formattedTime += `${padNumber(days)}:${padNumber(hours)}:`;
    } else if (hours > 0) {
        formattedTime += `${padNumber(hours)}:`;
    }
    formattedTime += `${padNumber(minutes)}:${padNumber(seconds)}`;

    return formattedTime;
};
